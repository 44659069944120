/* eslint-disable react/display-name */
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { random } from 'mathjs';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { InvoiceEndpoints, OperatorConfigurationEndpoints } from '../../../../v2/Lib/Api/Api';
import { Modal, Overlay } from '../../../components/application/FluxApp';
import { Button } from '../../../components/button/Button';
import { FluxCard } from '../../../components/card/FluxCard';
import { Currency } from '../../../components/currency/Currency';
import { FluxForm } from '../../../components/form/FluxForm';
import { InputTypes } from '../../../components/form/InputTypes';
import { ErrorModal } from '../../../components/modal/ErrorModal/ErrorModal';
import { BusyOverlay } from '../../../components/modal/Overlays/BusyOverlay';
import { FluxNotice } from '../../../components/notification/FluxNotice';
import { FluxNotification, NotificationType } from '../../../components/notification/FluxNotification';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { Table } from '../../../components/table/Table';
import { API } from '../../../lib/API/Api';
import { DataEndpoints } from '../../../lib/API/Endpoints';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { footerCurrencyValues } from '../../../lib/Helpers/Reports/Footer';
import { calculateRTP } from '../../../lib/Helpers/RTPHelper';
import './_casino-invoices.scss';


const addLineItemModel = (currencies) => {
    return {
        fields: [
            {
                name: 'Item',
                required: true
            },
            {
                name: 'Amount',
                required: true,
                type: InputTypes.Number
            },
            {
                name: 'Currency',
                type: InputTypes.Select,
                exact: true,
                values: currencies,
                required: true,
            },
            {
                name: 'tempId',
                value: random(0, 9999),
                hidden: true,
            },
        ]
    };
};


const searchModel = (currency, pov) => {
    return {
        fields: [
            {
                name: 'OperatorId',
                title: 'Operator',
                hidden: true,
                readOnly: true,
                value: pov,
            },
            {
                name: 'InvoiceTime',
                title: 'Invoice Time',
                type: InputTypes.Date,
                nullable: true,
                between: true,
                value: {
                    start: DateHelpers.yesterday(),
                    end: DateHelpers.tonight()
                }
            },
            {
                name: 'Currency',
                type: InputTypes.Select,
                exact: true,
                nullable: true,
                value: currency,
                data: {
                    source: 'enabledCurrencies',
                    key: 'CurrencyCode',
                    customTitle: (row) => {
                        return `(${row.CurrencyCode}) - ${row.Name}`;
                    },
                }
            },
            {
                name: 'ProviderIds',
                title: 'Provider',
                type: InputTypes.Select,
                nullable: true,
                multiple: true,
                data: {
                    source: 'providers/gaming',
                    key: 'id',
                    title: ['display']
                },
            },
        ]
    };
};

const resultModel = (opCurrency) => {
    return {
        fields: [
            {
                name: 'Provider',
                title: 'Provider'
            },
            {
                name: 'Currency',
                type: InputTypes.Text
            },
            {
                name: 'Bet',
                title: 'Bet',
                render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
                renderFooter: (val) => footerCurrencyValues(val),
                exportFooter: (val) => footerCurrencyValues(val, true)
            },
            {
                name: 'Cancel',
                title: 'Cancel',
                render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
                renderFooter: (val) => footerCurrencyValues(val),
                exportFooter: (val) => footerCurrencyValues(val, true)
            },
            {
                name: 'Win',
                title: 'Win',
                render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
                renderFooter: (val) => footerCurrencyValues(val),
                exportFooter: (val) => footerCurrencyValues(val, true)
            },
            {
                name: 'GGR',
                title: 'GGR',
                render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
                renderFooter: (val) => footerCurrencyValues(val),
                exportFooter: (val) => footerCurrencyValues(val, true)
            },
            {
                name: 'GGRExchanged',
                title: opCurrency,
                render: (val, row) => <Currency amount={val} sign={row.ExchangedCurrency} showSign={false} />,
                renderFooter: (val) => footerCurrencyValues(val),
                exportFooter: (val) => footerCurrencyValues(val, true)
            },
            {
                name: 'Rate',
            },
            {
                name: 'Round',
                title: 'Hands',
                type: InputTypes.Number
            },
            {
                name: 'RTP',
                title: 'RTP',
                type: InputTypes.Number,
                render: (val, row) => calculateRTP(row)
            }
        ]
    };
};


export const CasinoInvoices = () => {
    const user = useSelector((state) => state.user.loginInformation);
    const [povInfo, setPovInfo] = useState(null);
    const [filter, setFilter] = useState(null);
    const [invoiceNumber, setInvoiceNumber] = useState(null);
    const [defaultFee, setDefaultFee] = useState(null);

    const [saved, setSaved] = useState(null);
    const [addNew, setAddNew] = useState(false);
    const [lineItems, setLineItems] = useState([]);
    const [lineItem, setLineItem] = useState(null);
    const [currencies, setCurrencies] = useState(null);

    useEffect(() => {
        API.post(OperatorConfigurationEndpoints.Get + user.UserPrivileges.PointOfView).then((result) => {
            setPovInfo(result.result);
        });
        API.post(DataEndpoints.EnabledCurrencies).then((result) => {
            const dictionary = Object.fromEntries(result.result.map(item => [item.CurrencyCode, `(${item.CurrencyCode}) - ${item.Name}`]));
            setCurrencies(dictionary);
        });
    }, []);


    if (!povInfo) {
        return <></>;
    }
    if (!currencies) {
        return <></>;
    }
    searchModel(povInfo?.currency, user.UserPrivileges.PointOfView);

    return <flex className='vertical gap-10'>
        <FluxCard
            buttons={
                <flex className="align-right gap-10">
                    <span>Default Fee %:</span>
                    <input type='text' placeholder='Default Fee %' onChange={(e) => {
                        setDefaultFee(e.target.value);
                    }} />
                    <span>Invoice Number:</span>
                    <input type='text' placeholder='Invoice Number' onChange={(e) => {
                        setInvoiceNumber(e.target.value);
                    }} />
                    <Button
                        title="Download as PDF"
                        icon="file-pdf"
                        onClick={() => {
                            API.post(InvoiceEndpoints.CasinoInvoiceDownloadPDF,
                                {
                                    ...filter,
                                    TimeZone: window.user?.timeZoneConverted,
                                    InvoiceNumber: invoiceNumber,
                                    DefaultFee: defaultFee,
                                    LineItems: lineItems
                                }
                            ).then(async (result) => {
                                try {
                                    //`${result.result.filename}.pdf`
                                    //result.result.html
                                    Overlay.open(<BusyOverlay title={'loading'} />);
                                    const container = document.createElement("div");
                                    container.style.position = "absolute";
                                    container.style.top = "-9999px";
                                    container.innerHTML = result.result.html;
                                    document.body.appendChild(container); // Append the container temporarily to the DOM


                                    try {
                                        // Capture the element as a canvas
                                        const canvas = await html2canvas(container, {
                                            scale: 2, // Improve quality by increasing the resolution
                                        });
                                        const imgData = canvas.toDataURL("image/jpeg", 1.0); // Convert to image
                                        if (canvas.width < canvas.height) {//long content

                                            const pdf = new jsPDF("p", "px", [canvas.width, canvas.height]); // Custom size based on canvas dimensions

                                            // Add image to the PDF
                                            pdf.addImage(imgData, "JPEG", 0, 0, canvas.width, canvas.height);
                                            pdf.save(`${result.result.filename}.pdf`);
                                        } else {//short content
                                            // Convert pixel dimensions to millimeters for jsPDF
                                            const pdfWidth = (canvas.width * 25.4) / 96; // px to mm
                                            const pdfHeight = (canvas.height * 25.4) / 96; // px to mm

                                            // Create a PDF with the exact dimensions of the content
                                            const pdf = new jsPDF("landscape", "mm", [pdfWidth + 10, pdfHeight]); // +10mm for right margin

                                            // Add the image to the PDF with space on the right
                                            pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight); // Adjust width to fit within the content
                                            pdf.save(`${result.result.filename}.pdf`);
                                        }

                                        if (container.parentElement) {
                                            container.remove();
                                        }
                                    } catch (error) {
                                        console.error("Error generating PDF", error);
                                    } finally {
                                        // Remove the container from the DOM
                                        document.body.removeChild(container);
                                    }

                                    Overlay.close();
                                } catch (error) {
                                    Overlay.close();
                                    console.error('Error downloading the PDF:', error);
                                }
                            }).catch(e => {
                                Modal.open(<ErrorModal title='Error'>
                                    <FluxNotice type={NotificationType.Error}
                                        title={"Error downloading the PDF"}
                                        description={e?.error?.message ?? 'Service error please try later.'} />
                                </ErrorModal>);
                                return;
                            });
                        }}
                    />
                </flex>
            }
        >
        </FluxCard>
        <FluxCard title={'Add line Items - (Optional)'}
            toggleBody={true}
            hideBody={true}
            className='casino-invoice-lines'
        >
            <flex className='vertical gap-10'>
                {!addNew && !saved && <FluxCard>
                    <Button className='align-left' title='Add new' onClick={() => { setAddNew(true); setLineItem(null); }} />
                </FluxCard>}
                {saved && <FluxCard>
                    <flex className='vertical gap-10'>
                        <FluxNotification type={NotificationType.Success}>
                            <Icon icon='check-circle' />
                            <span>{lang('Line Item saved.')}</span>
                        </FluxNotification>
                        <buttons>
                            <Button title='Create Another' onClick={() => {
                                setSaved(null);
                            }} />
                        </buttons>
                    </flex>
                </FluxCard>}
                {addNew && <FluxCard title={'Edit / Add Line Item'}>
                    <FluxForm
                        model={addLineItemModel(currencies)}
                        data={lineItem}
                        onSubmit={(a) => {
                            if (!a.Item || !a.Amount || !a.Currency) return;
                            if (a.Currency.length > 3) {
                                var k = Object.keys(currencies).find(key => currencies[key] === a.Currency);
                                a.Currency = k;
                            }

                            var c = [...lineItems];
                            var temp = c.find(x => x.tempId == a.tempId);
                            if (temp) {
                                temp.Item = a.Item;
                                temp.Amount = a.Amount;
                                temp.Currency = a.Currency;
                            } else {
                                c.push(a);
                            }
                            setLineItems(c);
                            setAddNew(null);
                            setLineItem(null);
                        }}
                        onCancel={() => {
                            setAddNew(null);
                            setLineItem(null);
                        }}
                        onSubmitComplete={(data) => {
                            setAddNew(null);
                            setLineItem(null);
                            fetchCasinoCommisionFees();
                            setSaved(data.result);
                        }} />
                </FluxCard>}
                <Table
                    model={addLineItemModel(currencies)}
                    data={lineItems}
                    className='fx-borders fx-shadow'
                    buttons={(row) => {
                        return <flex key={row.Item} className='gap-10'>
                            <Button title='Edit' className='success' onClick={() => {
                                setLineItem(row);
                                setAddNew(true);
                            }} />
                            <Button title='Remove' className='warning' onClick={() => {
                                var c = [...lineItems];
                                c = c.filter(x => x.tempId != row.tempId);
                                setLineItems(c);
                            }} />
                        </flex>;
                    }} />
            </flex>
        </FluxCard>
        <FluxSearch
            className='casino-invoices'
            model={searchModel(povInfo.currency, user.UserPrivileges.PointOfView)}
            onFilterChange={(e) => setFilter(e)}
            endpoint={InvoiceEndpoints.CasinoInvoice}
            resultModel={resultModel(filter?.Currency ?? povInfo.currency)}
            exportToExcel={false}
            onPrepareResultModel={(model) => {
                var copy = { ...model };
                return copy;
            }}
        />
    </flex>;
};
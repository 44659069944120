/* eslint-disable react/display-name */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { Button } from '../../../../../components/button/Button';
import { InputTypes } from '../../../../../components/form/InputTypes';
import { Icon } from '../../../../../components/icon/Icon';
import { FluxSearch } from '../../../../../components/search/FluxSearch';
import { Event } from '../../../../../components/sports/SportEvent/Event';
import { TraderEndpoints } from '../../../../../lib/API/Endpoints';
import { DateHelpers } from '../../../../../lib/Helpers/DateHelpers';
import { lang } from '../../../../../lib/Localization/language';
import './_search-unsettled-tickets.scss';
import { TicketId } from '../../../../../lib/Models/TicketModels';

const Sports = {
    261354: 'Alpine Skiing',
    131506: 'American Football',
    1149103: 'Athletics',
    1149124: 'AUDL',
    389537: 'Australian Rules',
    1149093: 'Badminton',
    46957: 'Bandy',
    154914: 'Baseball',
    48242: 'Basketball',
    1149105: 'Basketball 3X3',
    621569: 'Beach Volleyball',
    1149101: 'Biathlon',
    1149113: 'Bowls',
    154919: 'Boxing',
    687897: 'Chess',
    452674: 'Cricket',
    307126: 'Curling',
    1149099: 'Cycling',
    154923: 'Darts',
    687890: 'E-Games',
    291987: 'Equine Sports',
    35706: 'Floorball',
    6046: 'Football',
    1149094: 'Formula 1',
    687887: 'Futsal',
    1149100: 'Gaelic Sports',
    687889: 'Golf',
    687893: 'Greyhounds',
    35709: 'Handball',
    530129: 'Hockey',
    687888: 'Horse Racing',
    1149117: 'Hurling',
    35232: 'Ice Hockey',
    1149119: 'Indy',
    1149123: 'Kabaddi',
    1149120: 'Lacrosse',
    1149112: 'Lottery',
    687896: 'MotoGP',
    165874: 'Motor Sports',
    1149102: 'Motorbikes',
    1149095: 'Nascar',
    1149097: 'Netball',
    1149121: 'Olympics',
    1149125: 'Padel',
    1149114: 'Poker',
    1149109: 'Politics',
    274792: 'Rugby League',
    274791: 'Rugby Union',
    1149115: 'Sailing',
    1149118: 'Ski Jumping',
    262622: 'Snooker',
    1149122: 'Softball',
    687895: 'Speedway',
    1149104: 'Squash',
    1149107: 'Sumo',
    1149096: 'Supercars',
    1149098: 'Surfing',
    265917: 'Table Tennis',
    54094: 'Tennis',
    1149126: 'Triathlon',
    687894: 'Trotting',
    1149111: 'TV-Games',
    1149108: 'Virtual sports',
    154830: 'Volleyball',
    388764: 'Waterpolo',
    1149110: 'Weather'
};

const model = {
    fields: [
        {
            name: 'OperatorId',
            title: 'Operator',
            type: InputTypes.Number,
            and: true
        },
        {
            name: 'SportId',
            title: 'Sport',
            type: InputTypes.Select,
            nullable: true,
            values: Sports,
            and: true
        },
        {
            name: 'TicketId',
            title: 'Ticket Id',
            type: InputTypes.Text,
            and: true
        },
        {
            name: 'EventId',
            title: 'Event Id',
            type: InputTypes.Text,
            and: true
        },
        {
            name: 'StartDate',
            title: 'Event Start Date',
            type: InputTypes.DateTime,
            between: true,
            tomorrow: true,
            value: {
                start: DateHelpers.addDay(DateHelpers.today(), -7),
                end: DateHelpers.addDay(DateHelpers.tonight(), 1)
            }
        },
        //Show all disabled -10/16/2024-
        // {
        //     name: 'ShowAll',
        //     title: 'Show All Tickets',
        //     type: InputTypes.Bool,
        //     and: true
        // },
        {
            name: 'ShowEnglish',
            title: 'Show Orig. Market Names',
            type: InputTypes.Bool,
            and: true
        }
    ],
    name: 'unsettled-tickets'
};

const ModelSportTicketResult = {
    fields: [
        {
            name: 'entryDate',
            title: 'Entry Date',
            type: InputTypes.DateTime
        },
        {
            name: 'ticketNo',
            title: 'Ticket No',
            type: InputTypes.Number,
            render: (val) => {
                return <TicketId id={val} disableUpdate />;
            }
        },
        {
            name: 'sportName',
            title: 'Sport'
        },
        {
            name: 'eventId',
            title: 'Event Id',
            type: InputTypes.Number
        },
        {
            name: 'event',
            title: 'Event Name',
            render: (val) => {
                if (val) {
                    const splitted = val.split('vs');
                    if (splitted && splitted.length == 2) {
                        return <flex className='vertical'>
                            <span title={splitted[0]}>{splitted[0]} vs</span>
                            <span title={splitted[1]}>{splitted[1]}</span>
                        </flex>;
                    }
                }
                return val;
            }
        },
        {
            name: 'startDate',
            title: 'Start Date',
            type: InputTypes.DateTime
        },
        {
            name: 'endDate',
            title: 'End Date',
            type: InputTypes.DateTime
        },
        {
            name: 'marketId',
            title: 'Market Id',
            type: InputTypes.Number
        },
        {
            name: 'marketName',
            title: 'Market Name'
        },
        {
            name: 'selectionName',
            title: 'Selection Name'
        }
    ],
    name: 'unsettled-tickets'
};

export const SearchUnsettledTickets = (props) => {
    const user = useSelector((state) => state.user).loginInformation;
    const operatorId = user.UserPrivileges.PointOfView;

    const [alreadySettledSelections, setAlreadySettledSelections] = useState([]);
    const [selectedEvent, selectEvent] = useState(null);
    const [showEnglish, toggleShowEnglish] = useState(false);
    const [research, setResearch] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setResearch(research => research + 1);
            setAlreadySettledSelections([]);
        }, 5 * 60 * 1_000);

        return () => clearInterval(interval);
    }, []);

    const title = () => {
        if (props.eventId) {
            return <React.Fragment><span>{lang('Event')}</span><Icon icon='chevron-right' type='fa-light' /><span>{props.eventId}</span><Icon icon='chevron-right' type='fa-light' /><span>{lang('Tickets')}</span></React.Fragment>;
        }

        return 'Search Unsettled Tickets';
    };


    return <React.Fragment>
        {selectedEvent && <Event showEnglish={showEnglish} id={selectedEvent.eventId} ticketNo={selectedEvent.ticketNo}
            onSelectionSettle={(selectionId) => {
                setAlreadySettledSelections([...alreadySettledSelections, parseInt(selectionId)]);
            }}
            onClose={() => {
                selectEvent(null);
            }} />}
        <FluxSearch
            className={'search-unsettled-tickets' + (selectedEvent ? ' hidden' : '')}
            {...props}
            title={title()}
            onFilterChange={(e) => {
                toggleShowEnglish(e.ShowEnglish);
            }}
            model={model}
            onPrepareModel={(m) => {
                m.fields.find(x => x.name == 'OperatorId').hidden = true;
                m.fields.find(x => x.name == 'OperatorId').value = operatorId;
                return m;
            }}
            resultModel={ModelSportTicketResult}
            endpoint={TraderEndpoints.GetOpenTickets(operatorId)}
            searchOnStart
            research={research}
            onAfterSearch={() => {
                setAlreadySettledSelections([]);
            }}
            onGetData={(datas) => {
                datas = datas.filter(x => !alreadySettledSelections.includes(x.selectionId));
                return datas;
            }}
            onPrepareResultModzel={(copy) => {
                if (!props.playerId) return copy;
                copy.name = 'unsettled-tickets-of-event-' + props.eventId;
                return copy;
            }}
            buttons={(row) => {
                return <div className='flex gap-5'>
                    <Button title='Open Event' onClick={() => {
                        selectEvent({ eventId: row.eventId, ticketNo: row.ticketNo });
                    }} />
                </div>;
            }}
        />
    </React.Fragment>;
};


SearchUnsettledTickets.propTypes = {
    playerId: PropTypes.number,
    eventId: PropTypes.any
};
import { createBrowserHistory } from 'history';
import { logout } from '../Helpers/User/Session';


export const hasAccess = (actionType, action) => {
    if (action?.affiliate && !window.user.AffiliateId) return;
    if (AccessibleItems.find(x => x === actionType)) return true;
    if (window.user === undefined) return false;
    if (Object.keys(window.user).length === 0) return false;
    if (!window.user.UserPrivileges) return false;
    var availableActions = window.user.UserPrivileges.AvailableActions;
    if (availableActions === undefined) return false;
    var res = (availableActions.find(x => x === actionType) !== undefined) || window.user.SuperUser;
    if (!res) {
        if (window.user.UserPrivileges.SpecialActions.find(x => x == actionType)) {
            return true;
        }
        var found = false;
        applicationMenu.children.map(action => {
            if (action.children != undefined) {
                action.children.map(actionChild => {
                    if (actionChild.children != undefined) {
                        if (actionChild.type == actionType) {
                            actionChild.children.map(actionChildChild => {
                                if ((availableActions.find(x => x === actionChildChild.type) !== undefined)) {
                                    found = true;
                                }
                            });
                        }

                    }
                });
            }
        });
        return found;
    }
    return res;
};


export const ActionTypes = {
    Home: 'Home',
    Logout: 'Logout',
    Dashboard: 'Dashboard',

    MyProfile: 'MyProfile',
    MyProfileInformation: 'MyProfileInformation',
    MyProfileActivity: 'MyProfileActivity',
    ChangeMyPassword: 'ChangeMyPassword',

    Casino: 'Casino',

    //
    Gaming: 'Gaming',
    GlobalGamingConfiguration: 'GlobalGamingConfiguration',
    OperatorGameConfiguration: 'OperatorGameConfiguration',
    ToggleGameStatus: 'ToggleGameStatus',
    SetOperatorGamingConfiguration: 'SetOperatorGamingConfiguration',
    ToggleOperatorGamingStatus: 'ToggleOperatorGamingStatus',
    EditGame: 'EditGame',

    //Sportsbook
    Sportsbook: 'Sportsbook',
    SportsbookMarkets: 'SportsbookMarkets',
    SportsbookCompetitors: 'SportsbookCompetitors',
    NewMarketType: 'NewMarketType',
    SportsbookMarketTypes: 'SportsbookMarketTypes',
    ShowMarkets: 'ShowMarkets',
    EditMarketType: 'EditMarketType',
    EditMarket: 'EditMarket',
    ToggleMarketStatus: 'ToggleMarketStatus',
    ToggleMarketTypeStatus: 'ToggleMarketTypeStatus',
    ShowEventMarkets: 'ShowEventMarkets',

    // Manual Bets
    ManualBet: 'ManualBet',
    PlaceManualBet: 'PlaceManualBet',
    BookABet: 'BookABet',
    SearchBookedBets: 'SearchBookedBets',
    SelectPlayerForManualBet: 'SelectPlayerForManualBet',
    BookedTicketOptions: 'BookedTicketOptions',
    LostBookABet: 'LostBookABet',
    WinBookABet: 'WinBookABet',
    ModifyBookedBet: 'ModifyBookedBet',

    //Lottery
    Lottery: 'Lottery',
    LotteryEvents: 'LotteryEvents',
    LotteryTickets: 'LotteryTickets',
    LotterySearchEvents: 'LotterySearchEvents',
    LotteryLimits: 'LotteryLimits',



    //
    Transactions: 'Transactions',
    SearchTransactions: 'SearchTransactions',
    SearchTransactionLimits: 'SearchTransactionLimits',
    TransactionLimitsModify: 'TransactionLimitsModify',
    SearchPSPTransactions: 'SearchPSPTransactions',
    SearchPlayerTransactions: 'SearchPlayerTransactions',
    SearchPendingDeposits: 'SearchPendingDeposits',
    SearchPendingWithdraws: 'SearchPendingWithdraws',
    SearchProcessingTransactions: 'SearchProcessingTransactions',
    EditTransactionData: 'EditTransactionData',
    ConfirmTransaction: 'ConfirmTransaction',
    RejectTransaction: 'RejectTransaction',
    TransactionDetails: 'TransactionDetails',
    ProcessingTransaction: 'ProcessingTransaction',
    CreatePlayerTransaction: 'CreatePlayerTransaction',
    CreateManualDeposit: 'CreateManualDeposit',
    CreateManualWithdraw: 'CreateManualWithdraw',
    DepositBonus: 'DepositBonus',
    WithdrawBonus: 'WithdrawBonus',
    CompleteBonus: 'CompleteBonus',
    ModifyDepositRollover: 'ModifyDepositRollover',
    ResetDepositRollover: 'ResetDepositRollover',



    // Risk Management
    RiskManagement: 'RiskManagement',
    RiskManagementDashboard: 'RiskManagementDashboard',
    SportsConfiguration: 'SportsConfiguration',
    RiskManagementConfigureApplications: 'RiskManagementConfigureApplications',
    RiskManagementConfigureGeneralLimits: 'RiskManagementConfigureGeneralLimits',
    RiskManagementConfigureSportLimits: 'RiskManagementConfigureSportLimits',
    RiskManagementConfigureSportGeneralPlayerLimits: 'RiskManagementConfigureSportGeneralPlayerLimits',
    RiskManagementConfigureSportPlayerLimits: 'RiskManagementConfigureSportPlayerLimits',
    RiskManagementConfigureCategoryLimits: 'RiskManagementConfigureCategoryLimits',
    RiskManagementConfigureLeagueLimits: 'RiskManagementConfigureLeagueLimits',

    RiskLevelsConfiguration: 'RiskLevelsConfiguration',

    RiskManagementDailySportsReports: 'RiskManagementDailySportsReports',
    RiskManagementManageEvents: 'RiskManagementManageEvents',
    RiskManagementBetMonitor: 'RiskManagementBetMonitor',
    RiskManagementEventLiabilities: 'RiskManagementEventLiabilities',
    RiskManagementUnsettledTickets: 'RiskManagementUnsettledTickets',
    RiskManagementDailyTickets: 'RiskManagementDailyTickets',
    RiskManagementOpenTickets: 'RiskManagementOpenTickets',
    RiskManagementResultedTickets: 'RiskManagementResultedTickets',
    RiskManagementLastRemainingResults: 'RiskManagementLastRemainingResults',

    RiskManagementMargins: 'RiskManagementMargins',

    PlayerUserGroups: 'PlayerUserGroups',
    CasinoRiskManagement: 'CasinoRiskManagement',
    ConfigureRiskGroup: 'ConfigureRiskGroup',


    TraderTools: 'TraderTools',
    TraderToolsEventBrowser: 'TraderToolsEventBrowser',

    // Reports
    Reports: 'Reports',
    SportReports: 'SportReports',
    DailyRevenuesSummaryReport: 'DailyRevenuesSummaryReport',
    CasinoRevenuesReport: 'CasinoRevenuesReport',
    CasinoProviderRevenuesReport: 'CasinoProviderRevenuesReport',
    CasinoProviderCommisionReport: 'CasinoProviderCommisionReport',
    GameRevenuesReport: 'GameRevenuesReport',
    PlayerGameRevenuesReport: 'PlayerGameRevenuesReport',
    PlayerCasinoRevenuesReport: 'PlayerCasinoRevenuesReport',
    CasinoPlayerGroupsRevenuesReport: 'CasinoPlayerGroupsRevenuesReport',
    ProviderRevenuesReport: 'ProviderRevenuesReport',
    OperatorRevenuesReport: 'OperatorRevenuesReport',
    BonusBetsBack: 'BonusBetsBack',
    ReportByOperatorDetail: 'ReportByOperatorDetail',
    PlayerReports: 'PlayerReports',
    ReportByPlayerGame: 'ReportByPlayerGame',
    BettingOperationalReport: 'BettingOperationalReport',
    CasinoOperationalReport: 'CasinoOperationalReport',
    
    ReportByBetResult: 'ReportByBetResult',
    PlayerRegisterReports: 'PlayerRegisterReports',
    FinancialReportByOperator: 'FinancialReportByOperator',
    FinancialReportByOperatorDetail: 'FinancialReportByOperatorDetail',
    SearchTransactionsAccountSummary: 'SearchTransactionsAccountSummary',
    FirstTransactionReport: 'FirstTransactionReport',
    ReportByFinancialOperatorTransaction: 'ReportByFinancialOperatorTransaction',
    DailyFinancialOperatorTransaction: 'DailyFinancialOperatorTransaction',
    ReportOfMyManualTransactions: 'ReportOfMyManualTransactions',
    ReportByFinancialManualTransaction: 'ReportByFinancialManualTransaction',
    ReportByFinancialNoExistTransaction: 'ReportByFinancialNoExistTransaction',
    DailyManualTransactionReport: 'DailyManualTransactionReport',
    MyDailyManualTransactionReport: 'MyDailyManualTransactionReport',
    
    SportRevenues: 'SportRevenues',
    SportPlayerRevenues: 'SportPlayerRevenues',
    SportPlayerGroupRevenues: 'SportPlayerGroupRevenues',
    SportBettingMarginReports: 'SportBettingMarginReports',
    SportCategoryRevenueSummary: 'SportCategoryRevenueSummary',
    SportRegionRevenueSummary: 'SportRegionRevenueSummary',
    SportLeagueReportsSummary: 'SportLeagueReportsSummary',
    SportEventReportsSummary: 'SportEventReportsSummary',
    SportMarketReportsSummary: 'SportMarketReportsSummary',
    SportSelectionReportsSummary: 'SportSelectionReportsSummary',
    SportPlayerReportsSummary: 'SportPlayerReportsSummary',
    SportBettingMarkets: 'SportBettingMarkets',
    SportBettingTickets: 'SportBettingTickets',
    SportEventRevenueReport: 'SportEventRevenueReport',
    SportBettingPlayersWithoutBets: 'SportBettingPlayersWithoutBets',
    SportOpenTicketOperations: 'SportOpenTicketOperations',
    SearchManualSettledTickets: 'SearchManualSettledTickets',
    SportEventRecovery: 'SportEventRecovery',
    Sports: 'Sports',
    
    GetTicketSelections: 'GetTicketSelections',
    FlagTicketsAsManualSettlement: 'FlagTicketsAsManualSettlement',
    
    CasinoInvoice: 'CasinoInvoice',
    CasinoInvoiceCommisionFees: 'CasinoInvoiceCommisionFees',
    
    
    // Horse
    HorseGreyHound: 'HorseGreyHound',
    HorseGreyhoundReports: 'HorseGreyhoundReports',
    HorseGreyhoundSummaryReport: 'HorseGreyhoundSummaryReport',
    HorseGreyHoundRegions: 'HorseGreyHoundRegions',
    HorseGreyHoundVenues: 'HorseGreyHoundVenues',
    HorseGreyHoundDistance: 'HorseGreyHoundDistance',
    HorseGreyHoundPlayers: 'HorseGreyHoundPlayers',
    HorseGreyHoundTickets: 'HorseGreyHoundTickets',
    HorseGreyHoundPendingTickets: 'HorseGreyHoundPendingTickets',
    HorseGreyHoundRejectedTickets: 'HorseGreyHoundRejectedTickets',
    HorseManualSettlement: 'HorseManualSettlement',
    //

    RetailReports: 'RetailReports',
    RetailAccountSummary: 'RetailAccountSummary',
    RetailTransactionsReport: 'RetailTransactionsReport',
    RetailTransactionsSummaryReport: 'RetailTransactionsSummaryReport',
    RetailPlayerTransactionsReport: 'RetailPlayerTransactionsReport',
    //

    BonusReports: 'BonusReports',
    BonusTransactions: 'BonusTransactions',
    PlayerBonusReports: 'PlayerBonusReports',
    PlayerBonusSummary: 'PlayerBonusSummary',
    SearchCompletedBonusPayouts: 'SearchCompletedBonusPayouts',
    CompletedBonusPayoutsSummary: 'CompletedBonusPayoutsSummary',
    RemovedBonusReports: 'RemovedBonusReports',
    DeductedBonusReports: 'DeductedBonusReports',


    SportPendingTickets: 'SportPendingTickets',
    SportRejectedTickets: 'SportRejectedTickets',
    SportResettlementReports: 'SportResettlementReports',



    Player: 'Player',
    CreatePlayer: 'CreatePlayer',
    CreatePlayerGroup: 'CreatePlayerGroup',
    PlayerProfile: 'PlayerProfile',
    MovePlayerToRetail: 'MovePlayerToRetail',
    AddPlayerToAffiliate: 'AddPlayerToAffiliate',
    PlayerRiskManagement: 'PlayerRiskManagement',
    ChangePlayerPassword: 'ChangePlayerPassword',
    UpdatePlayer: 'UpdatePlayer',
    ToggleEnablePlayer: 'ToggleEnablePlayer',
    CreatePlayerNote: 'CreatePlayerNote',
    SearchPlayer: 'SearchPlayer',
    SearchPlayerVerifications: 'SearchPlayerVerifications',
    SearchPlayerDetailWithBalance: 'SearchPlayerDetailWithBalance',
    ViewPlayerVerification: 'ViewPlayerVerification',
    SearchAffiliatePlayer: 'SearchAffiliatePlayer',
    SearchNewPlayers: 'SearchNewPlayers',
    PlayersWithSameIp: 'PlayersWithSameIp',
    PlayerShortcuts: 'PlayerShortcuts',
    SearchPlayerActivity: 'SearchPlayerActivity',
    SearchPlayerGameHistory: 'SearchPlayerGameHistory',
    SearchPlayerGameSessions: 'SearchPlayerGameSessions',
    GameHandDetails: 'GameHandDetails',
    PlayerAnnouncements: 'PlayerAnnouncements',


    //
    Marketing: 'Marketing',
    CreateNewMarketingTemplate: 'CreateNewMarketingTemplate',
    SearchEmailTemplates: 'SearchEmailTemplates',
    EmailTemplatesActions: 'EmailTemplatesActions',
    TemplatesEvents: 'TemplatesEvents',
    TemplatesEventsActions: 'TemplatesEventsActions',
    UpdateEmailTemplate: 'UpdateEmailTemplate',
    DeleteEmailTemplate: 'DeleteEmailTemplate',
    PreviewEmailTemplate: 'PreviewEmailTemplate',
    UpdateTemplatesEvents: 'UpdateTemplatesEvents',
    CreateEmailTemplate: 'CreateEmailTemplate',

    // Hub
    ShutdownHub: 'ShutdownHub',
    RestartHub: 'RestartHub',
    SendMessageToHub: 'SendMessageToHub',


    // Providers
    CasinoProviders: 'CasinoProviders',
    PaymentProviders: 'PaymentProviders',
    SearchProviders: 'SearchProviders',
    ActivateDeactivateProvider: 'ActivateDeactivateProvider',


    // Operators
    Operators: 'Operators',
    SearchOperators: 'SearchOperators',
    NewOperatorUser: 'NewOperatorUser',
    SearchOperatorUsers: 'SearchOperatorUsers',
    SearchOperatorUserActivity: 'SearchOperatorUserActivity',
    ShowOperatorUserActivityDetails: 'ShowOperatorUserActivityDetails',
    ToggleOperatorUserEnabled: 'ToggleOperatorUserEnabled',
    EditOperatorUser: 'EditOperatorUser',
    EditOperator: 'EditOperator',
    EditOperatorConfiguration: 'EditOperatorConfiguration',
    OperatorGamingConfiguration: 'OperatorGamingConfiguration',
    ActivateDeactivateOperator: 'ActivateDeactivateOperator',
    ActivateDeactivateProviderForOperator: 'ActivateDeactivateProviderForOperator',


    //platform
    NewOperator: 'NewOperator',
    UpdateOperator: 'UpdateOperator',
    SaveOperatorFeatures: 'SaveOperatorFeatures',
    SaveOperatorCasinoConfiguration: 'SaveOperatorCasinoConfiguration',
    SaveOperatorCountries: 'SaveOperatorCountries',
    SaveOperatorCurrencies: 'SaveOperatorCurrencies',
    SaveOperatorRiskConfiguration: 'SaveOperatorRiskConfiguration',
    GetOperatorConfiguration: 'GetOperatorConfiguration',
    SaveOperatorPaymentGatewayConfiguration: 'SaveOperatorPaymentGatewayConfiguration',

    //
    // Affiliates
    Affiliates: 'Affiliates',
    AffiliatePlayers: 'AffiliatePlayers',
    ListAffiliatePlayers: 'ListAffiliatePlayers',
    SearchAffiliateTransactions: 'SearchAffiliateTransactions',
    SearchAffiliateBonus: 'SearchAffiliateBonus',
    SearchAffiliatePlayerGameHistory: 'SearchAffiliatePlayerGameHistory',
    SearchAffiliatePlayerSettledTickets: 'SearchAffiliatePlayerSettledTickets',
    SearchAffiliatePlayerOpenTickets: 'SearchAffiliatePlayerOpenTickets',
    NewAffiliate: 'NewAffiliate',
    ListAffiliateGroups: 'ListAffiliateGroups',
    ListAffiliates: 'ListAffiliates',
    AffiliateSettlements: 'AffiliateSettlements',
    SearchAffiliateTransactionsSummary: 'SearchAffiliateTransactionsSummary',


    // Retails
    Retails: 'Retails',
    ListRetails: 'ListRetails',
    GetRetail: 'GetRetail',
    AddModifyRetail: 'AddModifyRetail',
    ListRetailUsers: 'ListRetailUsers',
    ListRetailPlayers: 'ListRetailPlayers',
    CreateRetailPlayer: 'CreateRetailPlayer',
    ModifyRetailPlayer: 'ModifyRetailPlayer',
    ListRetailTransactions: 'ListRetailTransactions',
    RetailSettlements: 'RetailSettlements',
    AddRetailCredit: 'AddRetailCredit',
    RemoveRetailCredit: 'RemoveRetailCredit',
    RetailOperations: 'RetailOperations',
    DepositRetailPlayer: 'DepositRetailPlayer',
    WithdrawRetailPlayer: 'WithdrawRetailPlayer',
    DisableEnableRetailPlayer: 'DisableEnableRetailPlayer',
    RetailUserBindings: 'RetailUserBindings',


    // POS
    POS: 'POS',
    POSUsers: 'POSUsers',
    POSTransactions: 'POSTransactions',
    POSDeposit: 'POSDeposit',
    POSWithdraw: 'POSWithdraw',
    POSSearchPlayers: 'POSSearchPlayers',
    POSDepositPlayer: 'POSDepositPlayer',
    POSWithdrawPlayer: 'POSWithdrawPlayer',
    POSProviderBindings: 'POSProviderBindings',
    POSSearchPlayerTransactions: 'POSSearchPlayerTransactions',


    // Operator Self Configuration
    Management: 'Management',
    AddNewUser: 'AddNewUser',
    SearchMyUsers: 'SearchMyUsers',
    ManageUserRights: 'ManageUserRights',
    EditMyUser: 'EditMyUser',
    ToggleMyUserEnabled: 'ToggleMyUserEnabled',
    ChangeMyUserPassword: 'ChangeMyUserPassword',
    SearchMyUserActivities: 'SearchMyUserActivities',
    Manage2FA: 'Manage2FA',


    // CONTENT MANAGEMENT
    CMS: 'CMS',
    ChangeGameThumbs: 'ChangeGameThumbs',
    GetMyCMSProviders: 'GetMyCMSProviders',
    GetMyCMSGames: 'GetMyCMSGames',
    CMSGetLanguage: 'CMSGetLanguage',
    LSportTeamIcons: 'LSportTeamIcons',
    CMSPromotions: 'CMSPromotions',
    CMSPages: 'CMSPages',
    Promotions: 'Promotions',
    AssignFreeSpinPromotion: 'AssignFreeSpinPromotion',
    FreeSpinCampaigns: 'FreeSpinCampaigns',
    ManagePromotions: 'ManagePromotions',
    SearchPeriodicPromotions: 'SearchPeriodicPromotions',
    RemoveFreeSpin: 'RemoveFreeSpin',
    SearchSlipLotteryTickets: 'SearchSlipLotteryTickets',
    ConfirmPendingPromotion: 'ConfirmPendingPromotion',
    RejectPendingPromotion: 'RejectPendingPromotion',
    RemoveBonusFromPlayer: 'RemoveBonusFromPlayer',
    ActivePromotionsUsage: 'ActivePromotionsUsage',


    // Promotions
    SearchPlayersWithBonus: 'SearchPlayersWithBonus',
    SearchPlayersWithoutBonus: 'SearchPlayersWithoutBonus',
    SearchPlayersPendingPromotions: 'SearchPlayersPendingPromotions',
    SearchPlayersWithFreeSpin: 'SearchPlayersWithFreeSpin',
    TournamentCenter: 'TournamentCenter',
    PromotionCodeDisableEnable: 'PromotionCodeDisableEnable',
    PromotionCodeConsumeSearch: 'PromotionCodeConsumeSearch',
    PromotionCodeSearch: 'PromotionCodeSearch',

    // Setup
    Setup: 'Setup',
    SetupSportsbook: 'SetupSportsbook',
    SetupExchangeRates: 'SetupExchangeRates',
    SportsbookTopLeagues: 'SportsbookTopLeagues',
    SportsLeagueWeights: 'SportsLeagueWeights',
    SportsbookTopEvents: 'SportsbookTopEvents',
    SportsbookEventCodes: 'SportsbookEventCodes',
    GetActiveBulletin: 'GetActiveBulletin',
    GetBulletin: 'GetBulletin',
    SaveBulletin: 'SaveBulletin',
    SportsbookSetup: 'SportsbookSetup',
    SportsbookEvents: 'SportsbookEvents',
    SportsbookCategories: 'SportsbookCategories',
    SportsbookRegions: 'SportsbookRegions',
    SportsbookLeagues: 'SportsbookLeagues',
    SetupMarkets: 'SetupMarkets',
    SetupMarketGroups: 'SetupMarketGroups',
    SetupOverviewMarkets: 'SetupOverviewMarkets',


    // ticket
    ManualTicketOptions: 'ManualTicketOptions',
    ManualTicketWin: 'ManualTicketWin',
    ManualTicketLost: 'ManualTicketLost',
    ManualTicketCancel: 'ManualTicketCancel',
    ManualTicketRollback: 'ManualTicketRollback',
    ManualTicketSettlement: 'ManualTicketSettlement',

    // Configuration
    Configuration: 'Configuration',
    ExchangeRates: 'ExchangeRates',
    ConfigurePaymentProviders: 'ConfigurePaymentProviders',
    PaymentProviderWhiteLists: 'PaymentProviderWhiteLists',
    ConfigureLanguages: 'ConfigureLanguages',
    ConfigureSportTaxes: 'ConfigureSportTaxes',
    ListExchangeRates: 'ListExchangeRates',
    ListPaymentProviders: 'ListPaymentProviders',
    ConfigureUserGroups: 'ConfigureUserGroups',
    ConfigureBackofficeLanguage: 'ConfigureBackofficeLanguage'
};

export const FluxActions = {
    Operators: [
        {
            type: ActionTypes.UpdateOperator,
            multiple: false,
            caption: 'Edit',
            icon: 'edit'
        },
        {
            type: ActionTypes.SaveOperatorCasinoConfiguration,
            multiple: false,
            caption: 'Configure Casino',
            icon: 'slot-machine'
        },
        {
            type: ActionTypes.SaveOperatorPaymentGatewayConfiguration,
            multiple: false,
            caption: 'Configure Payment Providers',
            icon: 'dollar-sign'
        }
    ],
    TemplatesEventsActions: [
        {
            type: ActionTypes.UpdateTemplatesEvents,
            multiple: false,
            caption: 'Update Templates Events',
            icon: 'edit'
        },
    ],
    EmailTemplatesActions: [
        {
            type: ActionTypes.UpdateEmailTemplate,
            multiple: false,
            caption: 'Update Email Template',
            icon: 'edit'
        },
        {
            type: ActionTypes.DeleteEmailTemplate,
            multiple: false,
            caption: 'Delete Email Template',
            icon: 'disable'
        },
        {
            type: ActionTypes.PreviewEmailTemplate,
            multiple: false,
            caption: 'Preview Email Template',
            icon: 'info'
        }
    ],
    SearchPlayer: [
        {
            type: ActionTypes.UpdatePlayer,
            multiple: false,
            caption: 'Edit Player',
            icon: 'edit'
        },
        {
            type: ActionTypes.ChangePlayerPassword,
            multiple: false,
            caption: 'Change Password',
            icon: 'changePassword'
        },
        {
            type: ActionTypes.ToggleEnablePlayer,
            multiple: false,
            caption: 'Deactivate Player',
            icon: 'disable'
        },
        {
            type: ActionTypes.MovePlayerToRetail,
            multiple: false,
            caption: 'Move to retail',
            icon: 'info'
        },
        {
            type: ActionTypes.AddPlayerToAffiliate,
            multiple: false,
            caption: 'Move to affiliate',
            icon: 'info'
        },
        {
            type: ActionTypes.PlayerProfile,
            multiple: false,
            caption: 'Player Profile',
            icon: 'info'
        }

    ],
    SearchPlayerVerifications: [
        {
            type: ActionTypes.ViewPlayerVerification,
            multiple: false,
            caption: 'View Documents',
            icon: 'info'
        },
        {
            type: ActionTypes.PlayerProfile,
            multiple: false,
            caption: 'Player Profile',
            icon: 'info'
        }
    ],
    SearchAffiliatePlayer: [
        {
            type: ActionTypes.PlayerProfile,
            multiple: false,
            caption: 'Player Profile',
            icon: 'info'
        }

    ],
    SelectPlayerForManualBet: [
        {
            type: ActionTypes.SelectPlayerForManualBet,
            multiple: false,
            caption: 'Select Player',
            icon: 'user'
        }
    ],
    SearchProviders: [
        {
            type: ActionTypes.ActivateDeactivateProvider,
            multiple: false,
            caption: 'Deactivate Provider',
            icon: 'disable'
        },
    ],
    SearchOperators: [
        {
            type: ActionTypes.EditOperator,
            multiple: false,
            caption: 'Edit Operator',
            icon: 'edit'
        },
        {
            type: ActionTypes.EditOperatorConfiguration,
            multiple: false,
            caption: 'Edit Operator Configuration',
            icon: 'configure'
        },
        {
            type: ActionTypes.ActivateDeactivateOperator,
            multiple: false,
            caption: 'Activate / Deactivate Operator',
            icon: 'configure'
        }
    ],
    SearchOperatorUserActivity: [
        {
            type: ActionTypes.ShowOperatorUserActivityDetails,
            multiple: false,
            caption: 'Show Token Movements',
            icon: 'question'
        }
    ],
    EditOperatorConfiguration: [
        {
            type: ActionTypes.ActivateDeactivateProviderForOperator,
            multiple: false,
            caption: 'Activate Deactivate Provider',
            icon: 'edit'
        }
    ],
    SearchOperatorUsers: [
        {
            type: ActionTypes.EditOperatorUser,
            multiple: false,
            caption: 'Edit User',
            icon: 'edit'
        },
        {
            type: ActionTypes.ToggleOperatorUserEnabled,
            multiple: false,
            caption: 'Activate Deactivate User',
            icon: 'block'
        }
    ],
    SearchPlayersWithFreeSpin: [
        {
            type: ActionTypes.PlayerProfile,
            caption: 'Profile',
            icon: 'id-card'
        },
        {
            type: ActionTypes.RemoveFreeSpin,
            caption: 'Remove',
            icon: 'reject'
        }
    ],
    SearchPlayersWithBonus: [
        {
            type: ActionTypes.PlayerProfile,
            multiple: false,
            caption: 'Player Profile',
            icon: 'info'
        },
        {
            type: ActionTypes.CompleteBonus,
            multiple: true,
            anonymous: true,
            caption: 'Complete',
            icon: 'check'
        },
        {
            type: ActionTypes.RemoveBonusFromPlayer,
            multiple: true,
            anonymous: true,
            caption: 'Remove',
            icon: 'reject'
        }
    ],
    SearchPlayersWithoutBonus: [
        {
            type: ActionTypes.PlayerProfile,
            multiple: false,
            caption: 'Player Profile',
            icon: 'info'
        }
    ],
    SearchPlayersPendingPromotions: [
        {
            type: ActionTypes.PlayerProfile,
            multiple: false,
            caption: 'Player Profile',
            icon: 'info'
        },
        {
            type: ActionTypes.ConfirmPendingPromotion,
            multiple: false,
            caption: 'Confirm',
            icon: 'check-circle'
        },
        {
            type: ActionTypes.RejectPendingPromotion,
            multiple: true,
            caption: 'Reject',
            icon: 'reject'
        }
    ],
    SearchSlipLotteryTickets: [
        {
            type: ActionTypes.PlayerProfile,
            multiple: false,
            caption: 'Player Profile',
            icon: 'info'
        }
    ],
    SeachPlayerTransactions: [
        {
            type: ActionTypes.TransactionDetails,
            multiple: false,
            caption: 'Details',
            icon: 'search'
        },
        {
            type: ActionTypes.RejectTransaction,
            multiple: false,
            caption: 'Reject',
            icon: 'reject'
        }
    ],
    SearchPlayerGameHistory: [
        {
            type: ActionTypes.GameHandDetails,
            anonymous: true,
            multiple: false,
            caption: 'Show Hands',
            icon: 'search'
        },
    ],
    SearchPlayerGameSessions: [
        {
            type: ActionTypes.GameHandDetails,
            anonymous: true,
            multiple: false,
            caption: 'Show Hands',
            icon: 'search'
        },
    ],
    SearchTransactions: [
        {
            type: ActionTypes.ShowTransactionsData,
            multiple: false,
            caption: 'Show Details',
            icon: 'confirm',
            anonymous: true
        },
        {
            type: ActionTypes.RejectTransaction,
            multiple: false,
            caption: 'Cancel',
            icon: 'reject',
            anonymous: true
        },
        {
            type: ActionTypes.PlayerProfile,
            multiple: false,
            caption: 'Player Profile',
            icon: 'info'
        }
    ],
    SearchPlayerTransactions: [
        {
            type: ActionTypes.ShowTransactionsData,
            multiple: false,
            caption: 'Show Details',
            icon: 'confirm',
            anonymous: true
        },
        {
            type: ActionTypes.RejectTransaction,
            multiple: false,
            caption: 'Cancel',
            icon: 'reject',
            anonymous: true
        }
    ],
    SearchPSPTransactions: [
        {
            type: ActionTypes.ShowTransactionsData,
            multiple: false,
            caption: 'Show Details',
            icon: 'confirm',
            anonymous: true
        },
        {
            type: ActionTypes.PlayerProfile,
            multiple: false,
            caption: 'Player Profile',
            icon: 'info'
        }
    ],
    SearchPendingDeposits: [
        {
            type: ActionTypes.ShowTransactionsData,
            multiple: false,
            caption: 'Show Details',
            icon: 'confirm',
            anonymous: true,
        },
        {
            type: ActionTypes.EditTransactionData,
            multiple: false,
            caption: 'Edit',
            icon: 'confirm',
            anonymous: true,
        },
        {
            type: ActionTypes.ConfirmTransaction,
            multiple: false,
            caption: 'Confirm',
            icon: 'confirm',
            anonymous: true,
        },
        {
            type: ActionTypes.RejectTransaction,
            multiple: false,
            caption: 'Reject',
            icon: 'reject',
            anonymous: true,
        },
        {
            type: ActionTypes.PlayerProfile,
            multiple: false,
            caption: 'Player Profile',
            icon: 'info'
        }
    ],
    SearchPendingWithdraws: [
        {
            type: ActionTypes.ShowTransactionsData,
            multiple: false,
            caption: 'Show Details',
            icon: 'confirm',
            anonymous: true
        },
        {
            type: ActionTypes.EditTransactionData,
            multiple: false,
            caption: 'Edit',
            icon: 'confirm',
            anonymous: true
        },
        {
            type: ActionTypes.ConfirmTransaction,
            multiple: false,
            caption: 'Confirm',
            icon: 'confirm',
            anonymous: true
        },
        {
            type: ActionTypes.RejectTransaction,
            multiple: false,
            caption: 'Reject',
            icon: 'reject',
            anonymous: true
        },
        {
            type: ActionTypes.PlayerProfile,
            multiple: false,
            caption: 'Player Profile',
            icon: 'info',
            anonymous: true
        }
    ],
    SearchProcessingTransactions: [
        {
            type: ActionTypes.ConfirmTransaction,
            multiple: false,
            caption: 'Confirm',
            icon: 'confirm'
        },
        {
            type: ActionTypes.RejectTransaction,
            multiple: false,
            caption: 'Reject',
            icon: 'reject'
        }
    ],
    CreatePlayerTransaction: [
        {
            type: ActionTypes.CreateManualDeposit,
            caption: 'Deposit',
            icon: 'hand-holding-usd'
        },
        {
            type: ActionTypes.CreateManualWithdraw,
            caption: 'Withdraw',
            icon: 'money-check-alt'
        }
    ],
    CreatePlayerNote: [
        {
            type: ActionTypes.CreatePlayerNote,
            caption: 'Add Note',
            icon: 'faStickyNote'
        }
    ],
    SearchMyUsers: [
        {
            type: ActionTypes.EditMyUser,
            multiple: false,
            caption: 'Edit User',
            icon: 'edit',
            anonymous: true
        },
        {
            type: ActionTypes.ChangeMyUserPassword,
            multiple: false,
            caption: 'Change Password',
            icon: 'key',
            anonymous: true
        },
        {
            type: ActionTypes.Manage2FA,
            multiple: false,
            caption: 'Manage 2FA',
            icon: 'key'
        }
    ],
    POSUsers: [
        {
            type: ActionTypes.POSDeposit,
            multiple: false,
            caption: 'Deposit',
            icon: 'dollar-sign'
        },
        {
            type: ActionTypes.POSWithdraw,
            multiple: false,
            caption: 'Withdraw',
            icon: 'dollar-sign'
        }
    ],
    POSSearchPlayers: [
        {
            type: ActionTypes.POSDepositPlayer,
            multiple: false,
            caption: 'Deposit',
            icon: 'dollar-sign'
        },
        {
            type: ActionTypes.POSWithdrawPlayer,
            multiple: false,
            caption: 'Withdraw',
            icon: 'dollar-sign'
        }

    ],
    POSTransactions: [

    ],
    GlobalGamingConfiguration: [
        {
            type: ActionTypes.ToggleGameStatus,
            caption: 'Global Enable / Disable'
        },
        {
            type: ActionTypes.EditGame,
            icon: 'edit',
            caption: 'Edit Game'
        }
    ],
    OperatorGameConfiguration: [
        {
            type: ActionTypes.SetOperatorGamingConfiguration,
            caption: 'Select Operator for Configuration'
        },
    ],
    SetOperatorGamingConfiguration: [
        {
            type: ActionTypes.ToggleOperatorGamingStatus,
            caption: 'Enable / Disable'
        },
    ],
    ListRetails: [
        {
            type: ActionTypes.AddModifyRetail,
            caption: 'Edit'
        },
        {
            type: ActionTypes.RetailOperations,
            caption: 'Select Retail'
        }
    ],
    ListRetailPlayers: [
        {
            type: ActionTypes.ModifyRetailPlayer,
            caption: 'Edit'
        },
        {
            type: ActionTypes.DisableEnableRetailPlayer,
            caption: 'Disable'
        },
        {
            type: ActionTypes.DepositRetailPlayer,
            caption: 'Deposit'
        },
        {
            type: ActionTypes.WithdrawRetailPlayer,
            caption: 'Withdraw'
        },
        {
            type: ActionTypes.ChangePlayerTag,
            caption: 'Tags'
        }
    ],
    ListRetailUsers: [
        {
            type: ActionTypes.RetailUserBindings,
            caption: 'Change Rights'
        },
        {
            type: ActionTypes.EditOperatorUser,
            caption: 'Edit'
        }
    ],
    ManualTicketOptions: [
        {
            type: ActionTypes.ManualTicketLost,
            caption: 'Lost'
        },
        {
            type: ActionTypes.ManualTicketWin,
            caption: 'Win'
        },
        {
            type: ActionTypes.ManualTicketCancel,
            caption: 'Cancel'
        },
        {
            type: ActionTypes.ManualTicketRollback,
            caption: 'Rollback'
        },
        {
            type: ActionTypes.ManualTicketSettlement,
            caption: 'Manual Settlement'
        }
    ],
    BookedTicketOptions: [
        {
            type: ActionTypes.LostBookABet,
            caption: 'Lost'
        },
        {
            type: ActionTypes.WinBookABet,
            caption: 'Win'
        },
        {
            type: ActionTypes.ModifyBookedBet,
            caption: 'Modify'
        }
    ],
    SportOpenTicketOperations: [
        {
            type: ActionTypes.GetTicketSelections,
            caption: 'Select Tickets'
        }
    ],
    BonusBetsBack: [
        {
            type: ActionTypes.BonusBetsBack,
            caption: 'Return Bonus Tickets'
        }
    ],
    AssignFreeSpinPromotion: [
        {
            type: ActionTypes.AssignFreeSpinPromotion,
            caption: 'Select Player'
        }
    ],
    FreeSpinCampaigns: [
        {
            type: ActionTypes.ManagePromotions,
            caption: 'Edit Campaign'
        }
    ],
    PromotionCodeSearch: [
        {
            type: ActionTypes.PromotionCodeConsumeSearch,
            multiple: false,
            caption: 'View Consumed Players'
        },
        {
            type: ActionTypes.PromotionCodeDisableEnable,
            multiple: false,
            caption: 'Disable/Enable Code'
        }
    ],
    HorseGreyHoundPendingTickets: [
        {
            type: ActionTypes.ConfirmTransaction,
            caption: 'Confirm'
        },
        {
            type: ActionTypes.RejectTransaction,
            caption: 'Reject'
        }
    ],
    SportPendingTickets: [
        {
            type: ActionTypes.ConfirmTransaction,
            caption: 'Confirm'
        },
        {
            type: ActionTypes.RejectTransaction,
            caption: 'Reject'
        }
    ]
};

export const applicationMenu = {
    children: [
        {
            title: 'Home',
            type: ActionTypes.Home,
            route: 'home',
            icon: 'home',
            anonymous: true
        },
        {
            title: 'Players',
            type: ActionTypes.Player,
            icon: 'user',
            route: 'players',
            children: [
                {
                    title: 'Create Player',
                    type: ActionTypes.CreatePlayer,
                    icon: 'user-plus',
                    route: 'create'
                },
                {
                    title: 'Player Groups',
                    type: ActionTypes.CreatePlayerGroup,
                    icon: 'user-group',
                    route: 'groups'
                },
                {
                    title: 'Search',
                    type: ActionTypes.SearchPlayer,
                    icon: 'user-magnifying-glass',
                    route: 'search'
                },
                {
                    title: 'Player Announcements',
                    type: ActionTypes.PlayerAnnouncements,
                    icon: 'mailbox',
                    route: 'announcements'
                },
                {
                    title: 'Ip Access',
                    type: ActionTypes.PlayersWithSameIp,
                    icon: 'exclamation-circle',
                    route: 'using-same-ip'
                }
            ]
        },
        {
            title: 'Players',
            type: ActionTypes.AffiliatePlayers,
            icon: 'user',
            affiliate: true,
            route: 'affiliate',
            children: [
                {
                    title: 'Search',
                    type: ActionTypes.ListAffiliatePlayers,
                    route: 'players',
                    icon: 'search'
                },
                {
                    title: 'Transactions',
                    type: ActionTypes.SearchAffiliateTransactions,
                    route: 'transactions',
                    hidden: true,
                    icon: 'dollar-sign'
                },
                {
                    title: 'Transactions Summary',
                    type: ActionTypes.SearchAffiliateTransactionsSummary,
                    route: 'transactions-summary',
                    icon: 'dollar-sign'
                },
                {
                    title: 'Bonus Transactions',
                    type: ActionTypes.SearchAffiliateBonus,
                    route: 'bonus',
                    icon: 'dollar-sign'
                },
                {
                    title: 'Game History',
                    type: ActionTypes.SearchAffiliatePlayerGameHistory,
                    route: 'casino',
                    icon: 'gamepad'
                },
                {
                    title: 'Tickets',
                    type: ActionTypes.SearchAffiliatePlayerOpenTickets,
                    route: 'tickets-open',
                    icon: 'gamepad'
                }
            ]
        },
        {
            title: 'Players',
            type: ActionTypes.Player,
            icon: 'user',
            affiliate: true,
            children: [
                {
                    title: 'Search',
                    type: ActionTypes.SearchPlayer,
                    icon: 'search'
                },
                {
                    title: 'Activity',
                    type: ActionTypes.SearchPlayerActivity,
                    icon: 'exclamation-circle',
                    hidden: true
                }
            ]
        },
        {
            title: 'Casino',
            type: ActionTypes.Casino,
            icon: 'slot-machine',
            route: 'casino',
            children: [
                {
                    type: ActionTypes.SearchPlayerGameHistory,
                    icon: 'search',
                    title: 'Search Hands',
                    route: 'hands'
                },
                {
                    type: ActionTypes.SearchPlayerGameSessions,
                    icon: 'list-timeline',
                    title: 'Search Sessions',
                    route: 'sessions'
                },
                {
                    type: ActionTypes.RiskManagementConfigureGeneralLimits,
                    icon: 'gauge-circle-minus',
                    title: 'Limits',
                    route: 'limits'
                }
            ]
        },
        {
            title: 'Sports',
            type: ActionTypes.Sports,
            icon: 'futbol',
            route: 'sports',
            children: [
                {
                    type: ActionTypes.SportBettingTickets,
                    icon: 'search',
                    title: 'Search Tickets',
                    route: 'tickets'
                },
                {
                    type: ActionTypes.SportsbookEvents,
                    icon: 'calendar-users',
                    title: 'Events',
                    hidden: true,
                    route: 'events'
                },
                {
                    type: ActionTypes.RiskManagement,
                    icon: 'gauge-circle-minus',
                    title: 'Risk Management',
                    route: 'risk',
                    children: [
                        {
                            type: ActionTypes.RiskManagementConfigureSportGeneralPlayerLimits,
                            icon: 'road-barrier',
                            title: 'Player Limits',
                            route: 'player-limits'
                        },
                        {
                            type: ActionTypes.RiskManagementConfigureSportLimits,
                            icon: 'road-barrier',
                            title: 'Betslip Limits',
                            route: 'limits'
                        },
                        {
                            type: ActionTypes.RiskLevelsConfiguration,
                            icon: 'cubes-stacked',
                            title: 'Event Limits',
                            route: 'levels'
                        },
                        {
                            type: ActionTypes.RiskManagementBetMonitor,
                            icon: 'display-chart-up-circle-dollar',
                            title: 'Bet Monitor',
                            route: 'monitor'
                        },
                        {
                            type: ActionTypes.RiskManagementUnsettledTickets,
                            icon: 'display-chart-up-circle-dollar',
                            title: 'Unsettled Tickets',
                            route: 'unsettled-tickets'
                        },
                        {
                            type: ActionTypes.RiskManagementEventLiabilities,
                            icon: 'display-chart-up-circle-dollar',
                            title: 'Event Liabilities',
                            route: 'event-liabilities'
                        },
                        {
                            type: ActionTypes.SportPendingTickets,
                            icon: 'search',
                            title: 'Investigated Tickets',
                            route: 'pending-tickets'
                        },
                        {
                            type: ActionTypes.SportRejectedTickets,
                            icon: 'search',
                            title: 'Rejected Tickets',
                            route: 'rejected-tickets'
                        },
                        {
                            type: ActionTypes.SportResettlementReports,
                            icon: 'search',
                            title: 'Resettlement Report',
                            route: 'resettled-tickets'
                        }

                    ]
                },
                {
                    type: ActionTypes.TraderTools,
                    icon: 'gauge-circle-minus',
                    title: 'Trader Tools',
                    route: 'trader',
                    children: [
                        {
                            type: ActionTypes.TraderToolsEventBrowser,
                            icon: 'search',
                            title: 'Event Browser',
                            route: 'event-browser'
                        }
                    ]
                }
            ]
        },
        {
            title: 'Horse',
            type: ActionTypes.HorseGreyHound,
            icon: 'horse',
            route: 'horse-greyhound',
            children: [
                {
                    type: ActionTypes.HorseGreyHoundTickets,
                    icon: 'search',
                    title: 'Search Tickets',
                    route: 'tickets'
                },
                {
                    type: ActionTypes.RiskManagement,
                    icon: 'gauge-circle-minus',
                    title: 'Risk Management',
                    route: 'risk',
                    children: [
                        {
                            type: ActionTypes.RiskManagementConfigureSportLimits,
                            icon: 'road-barrier',
                            title: 'Limits',
                            route: 'limits'
                        },
                        {
                            type: ActionTypes.RiskLevelsConfiguration,
                            icon: 'cubes-stacked',
                            title: 'Setup Levels',
                            route: 'levels'
                        },
                        {
                            type: ActionTypes.RiskManagementBetMonitor,
                            icon: 'display-chart-up-circle-dollar',
                            title: 'Bet Monitor',
                            route: 'monitor'
                        },
                        {
                            type: ActionTypes.HorseGreyHoundPendingTickets,
                            icon: 'search',
                            title: 'Investigated Tickets',
                            route: 'pending-tickets'
                        },
                        {
                            type: ActionTypes.HorseGreyHoundRejectedTickets,
                            icon: 'search',
                            title: 'Rejected Tickets',
                            route: 'rejected-tickets'
                        }
                    ]
                }
            ]
        },
        {
            title: 'Transactions',
            type: ActionTypes.Transactions,
            icon: 'arrow-down-arrow-up',
            route: 'transactions',
            children: [
                {
                    title: 'Search Transactions',
                    icon: 'magnifying-glass-dollar',
                    type: ActionTypes.SearchPlayerTransactions,
                    route: 'search'
                },
                {
                    title: 'Search Payment Transactions',
                    icon: 'magnifying-glass-dollar',
                    type: ActionTypes.SearchTransactions,
                    route: 'payment-transactions'
                },
                {
                    title: 'Search PSP Transactions',
                    icon: 'magnifying-glass-dollar',
                    type: ActionTypes.SearchPSPTransactions,
                    route: 'psp-transactions'
                },
                {
                    title: 'Pending Deposits',
                    icon: 'arrow-up-to-line',
                    type: ActionTypes.SearchPendingDeposits,
                    route: 'pending-deposits'
                },
                {
                    title: 'Pending Withdraws',
                    icon: 'arrow-down-to-line',
                    type: ActionTypes.SearchPendingWithdraws,
                    route: 'pending-withdraws'
                }
            ]
        },
        {
            title: 'Promotions',
            type: ActionTypes.Promotions,
            icon: 'award',
            route: 'promotions',
            children: [
                {
                    title: 'Manage Promotions',
                    icon: 'wrench',
                    type: ActionTypes.ManagePromotions,
                    route: 'manage-promotions'
                },
                {
                    title: 'Free Spins',
                    icon: 'slot-machine',
                    type: ActionTypes.AssignFreeSpinPromotion,
                    route: 'free-spins',
                    children: [
                        {
                            title: 'Free Spin Campaigns',
                            icon: 'plus',
                            type: ActionTypes.FreeSpinCampaigns,
                            route: 'campaigns'
                        },
                        {
                            title: 'Custom Free Spins',
                            icon: 'plus',
                            type: ActionTypes.AssignFreeSpinPromotion,
                            route: 'games'
                        },
                        {
                            title: 'Assigned Free Spins',
                            icon: 'search',
                            type: ActionTypes.SearchPlayersWithFreeSpin,
                            route: 'history'
                        }
                    ]
                },
                {
                    type: ActionTypes.TournamentCenter,
                    title: 'Tournament Center',
                    icon: 'award',
                    route: 'tournament-center'
                },
                {
                    type: ActionTypes.SearchPlayersWithBonus,
                    title: 'Player Bonus Progress',
                    icon: 'award',
                    route: 'bonus-progress'
                },
                {
                    type: ActionTypes.SearchPlayersPendingPromotions,
                    title: 'Pending Promotions',
                    icon: 'right-left-large',
                    route: 'pending'
                }
            ]
        },
        {
            title: 'Affiliates',
            type: ActionTypes.Affiliates,
            icon: 'handshake-simple',
            route: 'affiliates',
            children: [
                {
                    title: 'Groups',
                    icon: 'people-group',
                    type: ActionTypes.ListAffiliateGroups,
                    route: 'groups'
                },
                {
                    title: 'Affiliates',
                    icon: 'handshake-simple',
                    type: ActionTypes.ListAffiliates,
                    route: 'affiliate-center'
                }
            ]
        },
        {
            title: 'Retails',
            type: ActionTypes.Retails,
            icon: 'shop',
            route: 'retails',
            children: [
                {
                    title: 'Retail Center',
                    icon: 'users',
                    type: ActionTypes.ListRetails,
                    route: 'retail-center'
                }
            ]
        },
        {
            title: 'POS',
            type: ActionTypes.POS,
            icon: 'dollar-sign',
            route: 'pos',
            children: [
                {
                    type: ActionTypes.POSUsers,
                    route: 'users',
                    title: 'Users',
                    icon: 'list'
                },
                {
                    type: ActionTypes.POSTransactions,
                    route: 'transactions',
                    title: 'POS Transactions',
                    icon: 'dollar-sign'
                }
            ]
        },
        {
            type: ActionTypes.POSSearchPlayerTransactions,
            route: 'pos-search-player-transactions',
            title: 'POS Search Player Transactions',
            icon: 'dollar-sign'
        },
        {
            title: 'Reports',
            type: ActionTypes.Reports,
            icon: 'file-chart-column',
            route: 'reports',
            children: [
                {
                    title: 'Revenues',
                    type: 'Revenues',
                    icon: 'magnifying-glass-dollar',
                    route: 'revenues-reports',
                    children: [
                        {
                            title: 'Daily Revenues Summary',
                            type: ActionTypes.DailyRevenuesSummaryReport,
                            icon: 'sack-dollar',
                            route: 'daily-summary'
                        },
                        {
                            title: 'Casino',
                            icon: 'slot-machine',
                            type: 'Revenues',
                            route: 'casino',
                            children: [
                                {
                                    title: 'Summary',
                                    type: ActionTypes.CasinoRevenuesReport,
                                    icon: 'sack-dollar',
                                    route: 'revenus'
                                },
                                {
                                    title: 'Providers',
                                    type: ActionTypes.CasinoProviderRevenuesReport,
                                    icon: 'sack-dollar',
                                    route: 'daily-casino-providers'
                                },  
                                {
                                    title: 'Games',
                                    type: ActionTypes.GameRevenuesReport,
                                    icon: 'slot-machine',
                                    route: 'casino-games'
                                },
                                {
                                    title: 'Players',
                                    type: ActionTypes.PlayerCasinoRevenuesReport,
                                    icon: 'user-plus',
                                    route: 'casino-players'
                                },
                                {
                                    title: 'Player Groups',
                                    type: ActionTypes.CasinoPlayerGroupsRevenuesReport,
                                    icon: 'users',
                                    route: 'casino-player-groups'
                                },
                                {
                                    title: 'Operational',
                                    type: ActionTypes.CasinoOperationalReport,
                                    icon: 'tools',
                                    route: 'operational'
                                },
                            ]
                        },
                        {
                            title: 'Betting',
                            type: ActionTypes.SportReports,
                            icon: 'futbol',
                            route: 'sports',
                            children: [
                                {
                                    title: 'Summary',
                                    type: ActionTypes.SportRevenues,
                                    icon: 'sack-dollar',
                                    route: 'betting',
                                },
                                {
                                    title: 'Players',
                                    type: ActionTypes.SportPlayerRevenues,
                                    icon: 'user-plus',
                                    route: 'betting-players'
                                },
                                {
                                    title: 'Player Groups',
                                    type: ActionTypes.SportPlayerGroupRevenues,
                                    icon: 'users',
                                    route: 'betting-player-groups'
                                },
                                {
                                    title: 'Operational',
                                    type: ActionTypes.BettingOperationalReport,
                                    icon: 'tools',
                                    route: 'operational'
                                },
                            ]
                        },

                    ]
                },
                {
                    title: 'Sports Betting',
                    type: ActionTypes.SportReports,
                    icon: 'futbol',
                    route: 'sports-betting-reports',
                    children: [
                        {
                            title: 'Margins',
                            type: ActionTypes.SportBettingMarginReports,
                            icon: 'list-alt',
                            route: 'sport-betting-margins'
                        },
                        {
                            title: 'Categories',
                            type: ActionTypes.SportCategoryRevenueSummary,
                            icon: 'list-alt',
                            route: 'sport-betting-categories'
                        },
                        {
                            title: 'Regions',
                            type: ActionTypes.SportRegionRevenueSummary,
                            icon: 'list-alt',
                            route: 'sport-betting-regions'
                        },
                        {
                            title: 'Leagues',
                            type: ActionTypes.SportLeagueReportsSummary,
                            icon: 'list-alt',
                            route: 'sport-betting-leagues'
                        },
                        {
                            title: 'Events',
                            type: ActionTypes.SportEventReportsSummary,
                            icon: 'list-alt',
                            route: 'sport-betting-events'
                        },
                        {
                            title: 'Markets',
                            type: ActionTypes.SportMarketReportsSummary,
                            icon: 'list-alt',
                            route: 'sport-betting-markets'
                        },
                        {
                            title: 'Selections',
                            type: ActionTypes.SportSelectionReportsSummary,
                            icon: 'list-alt',
                            route: 'sport-betting-selections'
                        },
                        {
                            title: 'Players',
                            type: ActionTypes.SportPlayerReportsSummary,
                            icon: 'list-alt',
                            route: 'sport-betting-players'
                        },
                        {
                            title: 'Manual Settlements Report',
                            type: ActionTypes.SportBettingTickets,
                            icon: 'futbol',
                            route: 'sport-betting-tickets'
                        },
                        {
                            title: 'Event Summary',
                            type: ActionTypes.SportEventRevenueReport,
                            icon: 'futbol',
                            route: 'sport-betting-event-summary'
                        },
                        {
                            title: 'Players Without Bets',
                            type: ActionTypes.SportBettingPlayersWithoutBets,
                            icon: 'futbol',
                            route: 'sport-betting-player-without-bets'
                        }
                    ]
                },
                {
                    title: 'Horse / Greyhound',
                    type: ActionTypes.HorseGreyhoundReports,
                    icon: 'horse',
                    route: 'horsegreyhound-reports',
                    children: [
                        {
                            title: 'Revenues',
                            type: ActionTypes.HorseGreyhoundSummaryReport,
                            icon: 'horse',
                            route: 'horse-greyhound-revenues'
                        },
                        {
                            title: 'Regions',
                            type: ActionTypes.HorseGreyHoundRegions,
                            icon: 'horse',
                            route: 'horse-greyhound-regions'
                        },
                        {
                            title: 'Venues',
                            type: ActionTypes.HorseGreyHoundVenues,
                            icon: 'horse',
                            route: 'horse-greyhound-venues'
                        },
                        {
                            title: 'Distance',
                            type: ActionTypes.HorseGreyHoundDistance,
                            icon: 'horse',
                            route: 'horse-greyhound-distance'
                        },
                        {
                            title: 'Players',
                            type: ActionTypes.HorseGreyHoundPlayers,
                            icon: 'user',
                            route: 'horse-greyhound-players'
                        },
                        {
                            title: 'Tickets',
                            type: ActionTypes.HorseGreyHoundTickets,
                            icon: 'horse',
                            route: 'horse-greyhound-tickets'
                        }
                    ]
                },
                {
                    title: 'Player Summary',
                    type: 'Players',
                    icon: 'user',
                    route: 'playersummary-reports',
                    children: [
                        {
                            title: 'Revenue Summary',
                            type: ActionTypes.PlayerReports,
                            icon: 'users',
                            route: 'player-summary-revenue'
                        },
                        {
                            title: 'Game Summary',
                            type: ActionTypes.ReportByPlayerGame,
                            icon: 'dice-d6',
                            route: 'player-summary-game'
                        },
                    ]
                },
                {
                    title: 'Transactions',
                    type: 'TransactionReports',
                    icon: 'arrows-repeat',
                    route: 'transactions-reports',
                    children: [
                        {
                            title: 'Account Summary',
                            type: ActionTypes.SearchTransactionsAccountSummary,
                            icon: 'industry',
                            route: 'account-summary'
                        },
                        {
                            title: 'Providers',
                            type: ActionTypes.ReportByFinancialOperatorTransaction,
                            icon: 'industry',
                            route: 'providers'
                        },
                        {
                            title: 'Players',
                            type: ActionTypes.ReportByFinancialManualTransaction,
                            icon: 'industry',
                            route: 'players'
                        },
                        {
                            title: 'Players With No Exist Provider Transaction',
                            type: ActionTypes.ReportByFinancialNoExistTransaction,
                            icon: 'industry',
                            route: 'players-with-no-exist-provider-transaction'
                        },
                        {
                            title: 'First Transactions',
                            type: ActionTypes.FirstTransactionReport,
                            icon: 'industry',
                            route: 'first-transactions'
                        }
                    ]
                },
                {
                    title: 'Retail',
                    type: ActionTypes.RetailReports,
                    icon: 'shop',
                    route: 'retail-reports',
                    children: [
                        {
                            title: 'Account Summary',
                            type: ActionTypes.RetailAccountSummary,
                            icon: 'dollar-sign',
                            route: 'account-summary'
                        },
                        {
                            title: 'Transactions',
                            type: ActionTypes.RetailTransactionsReport,
                            icon: 'dollar-sign',
                            route: 'transactions'
                        },
                        {
                            title: 'Player Transactions',
                            type: ActionTypes.RetailPlayerTransactionsReport,
                            icon: 'dollar-sign',
                            route: 'player-transactions'
                        }
                    ]
                },
                {
                    title: 'Bonus',
                    type: ActionTypes.BonusReports,
                    route: 'bonus-reports',
                    icon: 'award',
                    children: [
                        {
                            title: 'Transactions',
                            type: ActionTypes.BonusTransactions,
                            icon: 'dollar-sign',
                            route: 'bonus-transactions'
                        },
                        {
                            title: 'Players Bonus',
                            type: ActionTypes.PlayerBonusReports,
                            icon: 'dollar-sign',
                            route: 'bonus-players'
                        },
                        {
                            title: 'Player Bonus Summary',
                            type: ActionTypes.PlayerBonusSummary,
                            icon: 'dollar-sign',
                            route: 'bonus-player-summary'
                        },
                        {
                            title: 'Completed Payouts',
                            type: ActionTypes.SearchCompletedBonusPayouts,
                            icon: 'dollar-sign',
                            route: 'bonus-completed-payouts'
                        },
                        {
                            title: 'Completed Payouts Summary',
                            type: ActionTypes.CompletedBonusPayoutsSummary,
                            icon: 'dollar-sign',
                            route: 'bonus-completed-payouts-summary'
                        },
                        {
                            title: 'Removed Bonuses',
                            type: ActionTypes.RemovedBonusReports,
                            icon: 'dollar-sign',
                            route: 'bonus-removed-bonuses'
                        },
                        {
                            title: 'Deducted Bonus Amounts on Withdraw',
                            type: ActionTypes.DeductedBonusReports,
                            icon: 'dollar-sign',
                            route: 'bonus-deducted-bonus-amounts'
                        }
                    ]
                },
                {
                    title: 'Invoices',
                    icon: 'magnifying-glass-dollar',
                    route: 'invoices',
                    children: [ 
                        {
                            title: 'Casino',
                            type: ActionTypes.CasinoInvoice,
                            icon: 'slot-machine',
                            route: 'casino'
                        },
                        {
                            title: 'Casino Invoice Fees',
                            type: ActionTypes.CasinoInvoiceCommisionFees,
                            icon: 'display-chart-up-circle-dollar',
                            route: 'casino-invoice-fees'
                        },
                    ]
                },
            ]
        },
        {
            title: 'CMS',
            type: ActionTypes.CMS,
            icon: 'folder-grid',
            route: 'cms',
            children: [
                {
                    title: 'Sports',
                    type: ActionTypes.SportsbookSetup,
                    icon: 'futbol',
                    route: 'sports',
                    children: [
                        {
                            title: 'Top Leagues',
                            type: ActionTypes.SportsbookTopLeagues,
                            icon: 'star',
                            route: 'top-leagues'
                        },
                        {
                            title: 'Top Events',
                            type: ActionTypes.SportsbookTopEvents,
                            icon: 'calendar-days',
                            route: 'top-events'
                        },
                        {
                            type: ActionTypes.SportsbookSetup,
                            icon: 'screwdriver-wrench',
                            title: 'Fixture',
                            route: 'fixture',
                            children: [
                                {
                                    type: ActionTypes.SportsbookCategories,
                                    icon: 'futbol',
                                    title: 'Categories',
                                    route: 'categories'
                                },
                                {
                                    type: ActionTypes.SportsbookRegions,
                                    icon: 'earth-europe',
                                    title: 'Regions',
                                    route: 'regions'
                                },
                                {
                                    type: ActionTypes.SportsbookLeagues,
                                    icon: 'list-tree',
                                    title: 'Leagues',
                                    route: 'leagues'
                                },
                                {
                                    type: ActionTypes.SportsbookEvents,
                                    icon: 'calendar-users',
                                    title: 'Events',
                                    route: 'events',
                                    hidden: true
                                }
                            ],
                        },
                        {
                            type: ActionTypes.SetupMarketGroups,
                            icon: 'layer-group',
                            title: 'Market Groups',
                            route: 'market-groups'
                        },
                        {
                            type: ActionTypes.SetupOverviewMarkets,
                            icon: 'futbol',
                            title: 'Overview Markets',
                            route: 'overview-markets',
                            hidden: true
                        }
                    ]
                },
                {
                    title: 'Translations',
                    type: ActionTypes.ConfigureLanguages,
                    icon: 'language',
                    route: 'languages'
                },
                {
                    title: 'Content',
                    type: ActionTypes.CMSPages,
                    icon: 'memo-circle-check',
                    route: 'content',
                    children: [
                        {
                            title: 'Pages',
                            type: ActionTypes.CMSPages,
                            icon: 'image',
                            route: 'pages'
                        },
                        {
                            title: 'Promotions',
                            type: ActionTypes.CMSPromotions,
                            icon: 'billboard',
                            route: 'promotions'
                        },
                        {
                            title: 'Providers',
                            type: ActionTypes.GetMyCMSProviders,
                            icon: 'cogs',
                            route: 'providers'
                        },
                        {
                            title: 'Games',
                            type: ActionTypes.GetMyCMSGames,
                            icon: 'slot-machine',
                            route: 'game-configurations'
                        }
                    ]
                }
            ]
        },
        {
            title: 'Setup',
            type: ActionTypes.Setup,
            icon: 'folder-grid',
            route: 'setup',
            children: [
                {
                    title: 'Configure Payment Providers',
                    type: ActionTypes.ConfigurePaymentProviders,
                    icon: 'display-chart-up-circle-dollar',
                    route: 'configure-payment-providers'
                },
                {
                    title: 'Payment Provider WhiteLists',
                    type: ActionTypes.PaymentProviderWhiteLists,
                    icon: 'users-slash',
                    route: 'payment-provider-white-lists'
                },
                {
                    title: 'Search Transactions Limits',
                    icon: 'tachometer-alt',
                    type: ActionTypes.SearchTransactionLimits,
                    route: 'transaction-limits'
                },
                {
                    title: 'Transactions Limit Add/Edit',
                    icon: 'gauge',
                    type: ActionTypes.TransactionLimitsModify,
                    route: 'transaction-limit-modify'
                },
            ]
        },
        {
            title: 'Administration',
            type: ActionTypes.Management,
            icon: 'shield',
            route: 'administration',
            children: [
                {
                    title: 'Users',
                    type: ActionTypes.SearchMyUsers,
                    icon: 'user-secret',
                    route: 'users'
                },
                {
                    title: 'User Rights',
                    type: ActionTypes.ManageUserRights,
                    icon: 'universal-access',
                    route: 'user-rights'
                },
                {
                    title: 'Audit Log',
                    type: ActionTypes.SearchMyUserActivities,
                    icon: 'pen-field',
                    route: 'audit-log'
                }
            ]
        },
        {
            title: 'Platform Settings',
            type: ActionTypes.Operators,
            icon: 'shield',
            route: 'platform-settings',
            children: [
                {
                    title: 'Operators',
                    type: ActionTypes.Operators,
                    icon: 'industry',
                    route: 'operators'
                },
                {
                    title: 'User Groups',
                    type: ActionTypes.ConfigureUserGroups,
                    icon: 'user-bounty-hunter',
                    route: 'groups'
                },
                {
                    title: 'Providers',
                    type: ActionTypes.CasinoProviders,
                    icon: 'chart-network',
                    route: 'providers'
                }
            ]
        },
        {
            title: 'Profile',
            type: ActionTypes.MyProfile,
            icon: 'id-card',
            route: 'profile',
            anonymous: true,
        },
        {
            title: 'Logout',
            type: ActionTypes.Logout,
            icon: 'door-open',
            route: 'logout',
            anonymous: true,
            execute: () => {
                logout();
            }
        }
    ]
};


const AccessibleItems = [
    ActionTypes.Home,
    ActionTypes.MyProfile,
    ActionTypes.MyProfileActivity,
    ActionTypes.MyProfileInformation,
    ActionTypes.Logout
];



export const getApplicationActions = (type) => {
    var item = applicationMenu.children.find(x => x.type === type);
    var actions = {
        items: []
    };
    if (item === undefined) {
        return actions;
    }

    item.children.map(children => {
        actions.items.push(
            {
                type: children.type.replace(type + '/', ''),
                icon: children.icon,
                title: children.title,
                actions: children.actions
            }
        );
    });

    return actions;
};


// get Action from General Actions Repo
export const getAction = (type) => {
    var actionRaw = FluxActions[type];
    try {
        if (actionRaw === undefined) {
            console.log('get action failed for ', type);
            return {
                convertToItems: function () {
                    return {
                        items: []
                    };
                }
            };
        }
        actionRaw.convertToItems = function () {
            return {
                items: actionRaw
            };
        };

        actionRaw.convertToButtons = function () {
            return actionRaw;
        };

        return actionRaw;
    } catch (e) {
        console.log('getAction failed for type ', type);
        console.error(e);
    }
};


export const getActions = (actionType, target) => {
    var action = {};
    var obj = {};
    if (target === undefined) {
        action = applicationMenu.children.find(x => x.type == actionType);
        var availableActions = [];
        if (action === undefined || action.children === undefined) return;
        action.children.map(action => {
            if (hasAccess(action.type)) {
                availableActions.push(action);
            } else {
                action.access = false;
                availableActions.push(action);
            }
        });
        obj = {
            type: action.type,
            url: action.url,
            icon: action.icon,
            title: action.title,
            items: availableActions
        };
    } else {
        action = target.items.find(x => x.type == actionType);
        if (action !== undefined) {
            obj = {
                items: []
            };
            action.actions.map(action => {
                obj.items.push({
                    type: action.type,
                    multiple: action.multiple,
                    caption: action.caption,
                    icon: action.icon
                });
            });
        }
    }

    obj.getActions = function (actionType) {
        return getActions(actionType, obj);
    };

    obj.getActionFromRoute = function () {
        var history = createBrowserHistory();
        let locations = history.location.pathname.split('/');
        let currentLocation = locations[locations.length - 1];

        try {
            let action = obj.items.find(x => x.type.toLocaleLowerCase() === currentLocation.toLocaleLowerCase());
            return action;
        } catch (err) {
            console.log(currentLocation + '<<', err);
        }
    };
    return obj;
};

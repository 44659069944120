/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { API } from '../../../lib/API/Api';
import { FluxCard } from '../../../components/card/FluxCard';
import { Button } from '../../../components/button/Button';
import { FluxNotification, NotificationType } from '../../../components/notification/FluxNotification';
import { Icon } from '../../../components/icon/Icon';
import { lang } from '../../../lib/Localization/language';
import { FluxForm } from '../../../components/form/FluxForm';
import { Table } from '../../../components/table/Table';
import { InvoiceEndpoints } from '../../../../v2/Lib/Api/Api';
import { InputTypes } from '../../../components/form/InputTypes';
import { FluxTab } from '../../../components/tab/FluxTab';
import { CMSEndpoints, DataEndpoints } from '../../../lib/API/Endpoints';

const CasinoCommisionFeeSourceForProvider = {
    1: 'Casino Provider',
};
const CasinoCommisionFeeSourceForPremiumGame = {
    2: 'Casino Provider Premium Game',
};
const modelForProvider = (gamingProviders) => {
    return {
        fields: [
            {
                name: 'Id',
                title: 'Id',
                nullable: true,
                hidden: true
            },
            {
                name: 'Source',
                type: InputTypes.Select,
                required: true,
                values: CasinoCommisionFeeSourceForProvider
            },
            {
                name: 'Data',
                title: 'Provider',
                type: InputTypes.Select,
                required: true,
                values: gamingProviders,
                data: {
                    source: 'providers/gaming',
                    key: 'id',
                    title: ['display']
                },
            },
            {
                name: 'Fee',
                required: true
            }
        ]
    };
};

const modelForPremiumGame = (games) => {
    return {
        fields: [
            {
                name: 'Id',
                title: 'Id',
                nullable: true,
                hidden: true
            },
            {
                name: 'Source',
                type: InputTypes.Select,
                required: true,
                values: CasinoCommisionFeeSourceForPremiumGame
            },
            {
                name: 'Data',
                title: 'Game',
                type: InputTypes.Select,
                required: true,
                values: games,
                data: {
                    source: CMSEndpoints.GetGamesSummary,
                    parameters: {},
                    parser: (result) => {
                        var results = [];
                        result.result.map(x => {
                            results.push({ title: x.providerName + ' - ' + x.name, value: x.gameId });
                        });
                        return results;
                    },
                },
            },
            {
                name: 'Fee',
                required: true
            }
        ]
    };
};

export const CasinoInvoiceCommisionFees = () => {
    const [saved, setSaved] = useState(null);
    const [addNew, setAddNew] = useState(false);
    const [casinoCommissionFees, setCasinoCommisionFees] = useState([]);
    const [casinoCommisionFee, setCasinoCommisionFee] = useState(null);
    const [gamingProviders, setGamingProviders] = useState(null);
    const [games, setGames] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(0);

    useEffect(() => {
        fetchCasinoCommisionFees();
        API.post(DataEndpoints.GameProviders)
            .then((result) => {
                const dictionary = Object.fromEntries(result.result.map(item => [item.id, item.display]));
                setGamingProviders(dictionary);
            });
        API.post(CMSEndpoints.GetGamesSummary)
            .then((result) => {
                const dictionary = Object.fromEntries(result.result.map(item => [item.gameId, (item.providerName + ' - ' + item.name)]));
                setGames(dictionary);
            });

    }, []);

    const fetchCasinoCommisionFees = () => {
        API.post(InvoiceEndpoints.GetCasinoCommisionFees, {}, 'Fetching casino commision fees', 'Unable to fetch datas')
            .then((result) => {
                setCasinoCommisionFees(result.result);
            });
    };
    let tabButtons = [
        {
            name: 'Provider',
            title: 'Provider'
        },
        {
            name: 'Premium Game',
            title: 'Premium Game'
        }
    ];
    if (!gamingProviders) return <></>;
    if (!games) return <></>;

    return <FluxTab buttons={tabButtons}
        className='overflow-hidden gap-10 fx-shadow fx-radius rounded-buttons'
        onChange={(e) => {
            setAddNew(null);
            setCasinoCommisionFee(null);
            setSelectedIndex(e);
        }}>
        <flex className='vertical gap-10' key={selectedIndex == 0}>
            {!addNew && !saved && <FluxCard>
                <Button className='align-left' title='Add new' onClick={() => { setAddNew(true); setCasinoCommisionFee(null); }} />
            </FluxCard>}
            {saved && <FluxCard>
                <flex className='vertical gap-10'>
                    <FluxNotification type={NotificationType.Success}>
                        <Icon icon='check-circle' />
                        <span>{lang('Commision Fee saved.')}</span>
                    </FluxNotification>
                    <buttons>
                        <Button title='Create Another' onClick={() => {
                            setSaved(null);
                        }} />
                    </buttons>
                </flex>
            </FluxCard>}
            {addNew && <FluxCard title={casinoCommisionFee?.Id ? 'Edit Commision Fee' : 'Add new Commision Fee'}>
                <FluxForm
                    key={selectedIndex == 0 && casinoCommisionFee?.Id}
                    model={modelForProvider(gamingProviders)}
                    data={casinoCommisionFee}
                    endpoint={InvoiceEndpoints.SaveCasinoCommisionFees}
                    confirm={true}
                    confirmTitle={casinoCommisionFee?.Id ? 'Edit Commision Fee' : 'Create new Commision Fee'}
                    confirmMessage={'Please confirm before ' + (casinoCommisionFee?.Id ? 'editing' : 'creating new') + ' Commision Fee'}
                    onCancel={() => {
                        setAddNew(null);
                        setCasinoCommisionFee(null);
                    }}
                    onSubmitComplete={(data) => {
                        setAddNew(null);
                        setCasinoCommisionFee(null);
                        fetchCasinoCommisionFees();
                        setSaved(data.result);
                    }} />
            </FluxCard>}
            <FluxCard key={selectedIndex == 0} title='Casino Commission Fee'>
                <Table key={selectedIndex == 0} model={modelForProvider(gamingProviders)} data={casinoCommissionFees.filter(x => x.Source == 1)} className='fx-borders fx-shadow'
                    buttons={(row) => {
                        return <flex className='gap-10'>
                            <Button title='Edit' className='success' onClick={() => {
                                setCasinoCommisionFee(row);
                                setAddNew(true);
                            }} />
                        </flex>;
                    }} />
            </FluxCard>
        </flex>
        {/* ---------------------------------------------------------------------------------------------------- */}
        <flex className='vertical gap-10' key={selectedIndex == 1}>
            {!addNew && !saved && <FluxCard>
                <Button className='align-left' title='Add new' onClick={() => { setAddNew(true); setCasinoCommisionFee(null); }} />
            </FluxCard>}
            {saved && <FluxCard>
                <flex className='vertical gap-10'>
                    <FluxNotification type={NotificationType.Success}>
                        <Icon icon='check-circle' />
                        <span>{lang('Commision Fee saved.')}</span>
                    </FluxNotification>
                    <buttons>
                        <Button title='Create Another' onClick={() => {
                            setSaved(null);
                        }} />
                    </buttons>
                </flex>
            </FluxCard>}
            {addNew && <FluxCard title={casinoCommisionFee?.Id ? 'Edit Commision Fee' : 'Add new Commision Fee'}>
                <FluxForm
                    key={selectedIndex == 1 && casinoCommisionFee?.Id}
                    model={modelForPremiumGame(games)}
                    data={casinoCommisionFee}
                    endpoint={InvoiceEndpoints.SaveCasinoCommisionFees}
                    confirm={true}
                    confirmTitle={casinoCommisionFee?.Id ? 'Edit Commision Fee' : 'Create new Commision Fee'}
                    confirmMessage={'Please confirm before ' + (casinoCommisionFee?.Id ? 'editing' : 'creating new') + ' Commision Fee'}
                    onCancel={() => {
                        setAddNew(null);
                        setCasinoCommisionFee(null);
                    }}
                    onSubmitComplete={(data) => {
                        setAddNew(null);
                        setCasinoCommisionFee(null);
                        fetchCasinoCommisionFees();
                        setSaved(data.result);
                    }} />
            </FluxCard>}
            <FluxCard title='Casino Premium Game Commission Fee' key={selectedIndex == 1}>
                <Table key={selectedIndex == 1} model={modelForPremiumGame(games)} data={casinoCommissionFees.filter(x => x.Source == 2)} className='fx-borders fx-shadow'
                    buttons={(row) => {
                        return <flex className='gap-10'>
                            <Button title='Edit' className='success' onClick={() => {
                                setCasinoCommisionFee(row);
                                setAddNew(true);
                            }} />
                        </flex>;
                    }} />
            </FluxCard>
        </flex>
    </FluxTab>;
};

import './_daily-player-group-limits.scss';
import './_sport-player-limits.scss';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { API } from '../../../../lib/API/Api';
import { DataEndpoints, RiskLimitEndpoints } from '../../../../lib/API/Endpoints';
import { lang } from '../../../../lib/Localization/language';
import { fetchGroups, showAddNewGroupModal } from '../../../../pages/Players/PlayerGroups/PlayerGroups';
import { Button } from '../../../button/Button';
import { FluxCard } from '../../../card/FluxCard';
import { FluxInput } from '../../../form/Components/FluxInput';
import { MultipleSelectInput } from '../../../form/Components/MultipleSelectInput';
import { InputTypes } from '../../../form/InputTypes';
import { Table } from '../../../table/Table';

//to show filled data on top
const getComparisonValue = (item) =>
    item.CurrentMaxPayout > 0 ? item.CurrentMaxPayout :
        item.CurrentStakeLimit > 0 ? item.CurrentStakeLimit :
            item.CurrentLiabilityLimit > 0 ? item.CurrentLiabilityLimit :
                item.MaxPayout > 0 ? item.MaxPayout :
                    item.StakeLimit > 0 ? item.StakeLimit :
                        item.LiabilityLimit > 0 ? item.LiabilityLimit :
                            -1;

const sortGroups = (a, b) => {
    const aValue = getComparisonValue(a);
    const bValue = getComparisonValue(b);
    return bValue - aValue;
};
const field = {
    values: {
        'All': 1,
        'Online': 2,
        'Retail': 3
    },
    value: 1
};

const UTCTimes = () => {
    var copy = [];
    var i = 0;
    for (i = 0; i < 24; i++) {
        copy.push({
            title: 'UTC ' + (i.toString().padStart(2, '0')) + ':00',
            value: i
        });
    }

    return copy;
};

const calculatePercentages = (current, limit) => {
    const currentValue = parseInt(current ?? 0);
    const limitValue = parseInt(limit ?? 0);
    if (limitValue === 0) return { currentPercentage: 100, limitPercentage: 0 };

    const currentPercentage = (currentValue / limitValue) * 100;
    const limitPercentage = 100 - currentPercentage;

    return {
        currentValue,
        limitValue,
        currentPercentage: currentValue > limitValue ? 100 : currentPercentage,
        limitPercentage: currentValue > limitValue ? 0 : limitPercentage
    };
};

const renderStatistic = (label, current, limit, updateKey, rowId) => {
    const { currentValue, limitValue, currentPercentage, limitPercentage } = calculatePercentages(current, limit);

    return (
        <flex key={`${rowId}_${label}_${updateKey}`} className='event-statistics'>
            <flex className='vertical'>
                <flex className='gap-10 stat'>
                    <span>{label}</span>
                    <span className='current-score'>{currentValue}</span>
                    <flex className='vertical stat-progress'>
                        <flex className='bar'>
                            <flex className='current' style={{ width: `${currentPercentage}%` }} />
                            <flex className='limit' style={{ width: `${limitPercentage}%` }} />
                        </flex>
                    </flex>
                    <span className='limit-score'>{limitValue}</span>
                </flex>
            </flex>
        </flex>
    );
};


const tableModel = (enabledCurrencies, updateKey) => {
    return {
        fields: [
            { name: 'Id', hidden: true },
            { name: 'Name', title: 'Group Name' },
            { name: 'Description', title: 'Group Description' },
            {
                name: 'Retail',
                title: 'Online/Retail',
                type: InputTypes.Bool,
                render: (val) => {
                    return <flex>
                        <span>{lang(val ? 'Retail' : 'Online')}</span>
                    </flex>;
                }
            },
            {
                name: 'Progress',
                title: 'Progress',
                render: (val, row) => {
                    return <flex key={`${row.Id}_${updateKey}`} className='vertical gap-10'>
                        {renderStatistic('Stake Limit', row.CurrentStakeLimit, row.StakeLimit, updateKey, row.Id)}
                        {renderStatistic('Max Payout', row.CurrentMaxPayout, row.MaxPayout, updateKey, row.Id)}
                        {renderStatistic('Liability Limit', row.CurrentLiabilityLimit, row.LiabilityLimit, updateKey, row.Id)}
                    </flex>
                }
            },
            {
                name: 'StakeLimit',
                title: 'Stake Limit',
                render: (val, row, field, context) => <input type='number'
                    key={row.Id}
                    name={field.name}
                    min={0}
                    step={0.01}
                    defaultValue={val}
                    onChange={(e) => context.update(e, row)} />
            },
            {
                name: 'MaxPayout',
                title: 'Max Payout',
                render: (val, row, field, context) => <input type='number'
                    key={row.Id}
                    name={field.name}
                    min={0}
                    step={0.01}
                    defaultValue={val}
                    onChange={(e) => context.update(e, row)} />
            },
            {
                name: 'LiabilityLimit',
                title: 'Liability Limit',
                render: (val, row, field, context) => <input type='number'
                    key={row.Id}
                    name={field.name}
                    min={0}
                    step={0.01}
                    defaultValue={val}
                    onChange={(e) => context.update(e, row)} />
            },
            {
                name: 'Currency',
                render: (val, row, _, context) => <FluxInput
                    key={row.Id}
                    data={{ Currency: val }}
                    field={{
                        name: 'Currency',
                        type: InputTypes.Select,
                        required: false,
                        nullable: true,
                        values: enabledCurrencies
                    }}
                    onChange={(field, value) => context.updateField(field, value, row)}>
                </FluxInput>
            },
            {
                name: 'UtcReset',
                title: 'UTC Times to Daily Reset',
                render: (val, row, _, context) => {
                    return <FluxInput
                        key={row.Id}
                        data={{
                            UtcReset: val
                        }}
                        field={{
                            name: 'UtcReset',
                            type: InputTypes.Select,
                            required: false,
                            nullable: true,
                            values: UTCTimes()
                        }}
                        onChange={(field, value) => context.updateField(field, value, row)}>
                    </FluxInput>
                }
            },
        ]
    };
}

export const SportPlayerLimitsWithRiskLevelsDailyPlayerGroupLimits = () => {
    const user = useSelector((state) => state.user);
    const [groups, setGroups] = useState([]);
    const [showFiltersMenu, toggleFilters] = useState(false);
    const [searchFilter, setSearchFilter] = useState(null);
    const [onlineFilter, setOnlineFilter] = useState(1);
    const [currencies, setCurrencies] = useState(null);
    const [updateKey, setUpdateKey] = useState(0);

    useEffect(() => {
        API.post(DataEndpoints.EnabledCurrencies).then((result) => {
            var operatorCurrencies = []
            result.result.map(x => {
                operatorCurrencies.push({ title: `(${x.CurrencyCode}) - ${x.Name}`, value: x.CurrencyCode })
            });
            setCurrencies(operatorCurrencies);
            fetchPlayerGroups();
        });
    }, []);

    const getData = () => {
        var res = groups;

        if (searchFilter) {
            var text = searchFilter.toLowerCase();
            res = groups.filter(x => x.Name.toLowerCase().includes(text));
            if (onlineFilter == 2) {
                res = res.filter(x => x.Retail == false);
            } else if (onlineFilter == 3) {
                res = res.filter(x => x.Retail == true);
            }
        }
        else {
            if (onlineFilter == 2) {
                res = res.filter(x => x.Retail == false);
            } else if (onlineFilter == 3) {
                res = res.filter(x => x.Retail == true);
            }
        }
        return res;
    };

    const fetchPlayerGroups = () => {
        fetchGroups().then((result) => {
            API.post(RiskLimitEndpoints.GetDailyPlayerGroupLimit(user.loginInformation.UserPrivileges.PointOfView)).then((res) => {
                result.result.map(x => {
                    const data = res.result.find(y => y.id == x.Id);
                    if (data) {
                        x['UtcReset'] = data.utcReset;
                        x['Currency'] = data.currency;
                        x['StakeLimit'] = data.stakeLimit;
                        x['MaxPayout'] = data.maxPayout;
                        x['LiabilityLimit'] = data.liabilityLimit;
                        x['CurrentStakeLimit'] = data.currentStakeLimit;
                        x['CurrentMaxPayout'] = data.currentMaxPayout;
                        x['CurrentLiabilityLimit'] = data.currentLiabilityLimit;
                    }
                    return x;
                });
                setGroups(result.result.sort((a, b) => sortGroups(a, b)));
            });
        });
    };

    const roundToHundredth = (value) => {
        if (value >= 0.001 && value <= 0.009) {
            return 0.01;
        }
        return Math.round(value * 100) / 100;
    };

    const context = {
        updateField: (field, val, row) => {
            var copy = groups;
            var data = copy.find(x => x.Id == row.Id);
            if (data[field.name] == val) return;
            data[field.name] = val;
            setGroups(copy.sort((a, b) => sortGroups(a, b)));
        },
        update: (e, row) => {
            var copy = groups;
            var field = copy.find(x => x.Id == row.Id);
            var value = e.target.value;
            if (e.target.value == field[e.target.name]) return;
            if (e.target.type == 'number') {
                value = roundToHundredth(parseFloat(value));
                e.target.value = value;
            }
            field[e.target.name] = value;
            setGroups(copy.sort((a, b) => sortGroups(a, b)));
        }
    };

    const save = () => {
        API.post(RiskLimitEndpoints.SaveDailyPlayerGroupLimit(user.loginInformation.UserPrivileges.PointOfView), groups)
            .then(() => {
                setUpdateKey(Date.now());
                // not sure to do what here.
            });
    };

    if (!currencies) return <></>;

    return <FluxCard title='Daily Player Group Limits (The limits under this configuration will reset daily according to the provided time zone.)' className={'daily-player-group-limits'}
        buttons={<flex className='gap-5 align-right'>
            <Button title={showFiltersMenu ? 'Close Filters' : 'Open Filters'} onClick={() => toggleFilters(!showFiltersMenu)} />
            <Button className={'marginRight'} title='Add Group' onClick={() => showAddNewGroupModal(() => fetchPlayerGroups())} />
            <Button className={'success marginRight'} title='Save' onClick={() => save()} />
        </flex>}>
        {
            showFiltersMenu &&
            <flex className='padding gap-10 border'>
                <flex className='padding border-bottom gap-10 fx-shadow' >
                    <span className='bold margin-end no-wrap'>{lang('Search in tree')}</span>
                    <input type='text' className='w-100' placeholder='Search' defaultValue={null}
                        onChange={(e) => setSearchFilter(e.target.value)} />
                </flex>
                <flex className={'padding border-bottom gap-10 fx-shadow'}>
                    <span className='bold'>{lang('Type')}</span>
                    <MultipleSelectInput
                        data={1}
                        field={field}
                        onChange={(a, b) => setOnlineFilter(b)} />
                </flex>
                <hr></hr>
            </flex>
        }
        <Table
            className={'fx-borders fx-shadow limits-table'}
            key={'Id'}
            model={tableModel(currencies, updateKey)}
            context={context}
            data={getData()} />
    </FluxCard >;
};
import './_sport-player-limits.scss';

import React from 'react';
import { FluxTab } from '../../../tab/FluxTab';
import { SportPlayerLimitsWithRiskLevelsDailyPlayerGroupLimits } from './SportPlayerLimitsWithRiskLevelsDailyPlayerGroupLimits';
import { SportPlayerLimitsWithRiskLevelsPlayerLimits } from './SportPlayerLimitsWithRiskLevelsPlayerLimits';

export const SportPlayerLimitsWithRiskLevels = () => {
    var tabButtons = [
        {
            title: 'Player Limits'
        },
        {
            title: 'Daily Player Group Limits'
        },
    ];


    return <flex className='risk-limits vertical gap-20 overflow-hidden'>
        <FluxTab buttons={tabButtons} className='gap-10 fx-shadow fx-radius rounded-buttons overflow-hidden'>
            <SportPlayerLimitsWithRiskLevelsPlayerLimits />
            <SportPlayerLimitsWithRiskLevelsDailyPlayerGroupLimits />
        </FluxTab>
    </flex>;
};